import { useState, useEffect, useContext } from 'react';
import { ContextProps, GlobalContext } from 'context/global-context';
import styled from 'styled-components';

export type CountdownProps = {
  millisecondsLeft: number;
  big?: boolean;
  onCompleted?: () => void;
  label?: string;
};

type TimeleftState = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} | null;

export const Countdown: React.FC<CountdownProps> = ({ millisecondsLeft, big = false, onCompleted, label }) => {
  const [timeLeft, setTimeLeft] = useState<TimeleftState>(null);
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  const calculateTimeLeft = (): TimeleftState => {
    const difference = millisecondsLeft - +new Date();
    // const difference = millisecondsLeft;
    let time = null;
    if (difference > 0) {
      time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return time;
  };

  useEffect(() => {
    if (millisecondsLeft > 0) {
      const interval = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft) {
          setTimeLeft(newTimeLeft);
        } else {
          setTimeLeft(null);
          clearInterval(interval);
          if (onCompleted) onCompleted();
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [millisecondsLeft, onCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!timeLeft) {
    return null;
  }

  const twoZeroes = (num: number): string => {
    const str = num.toString();
    if (str.length === 1) {
      return `0${str}`;
    }
    return str;
  };

  const { days, hours, minutes, seconds } = timeLeft;

  if (big) {
    return (
      <StyledCountdown className="countdown big">
        <div className="time-item">
          <span className="value">{days}</span>
          <span className="label">{dictionary[`day${days > 1 ? 's' : ''}`]}</span>
        </div>
        <div className="time-item">
          <span className="value">{twoZeroes(hours)}</span>
          <span className="label">{dictionary[`hour${hours > 1 ? 's' : ''}`]}</span>
        </div>
        <div className="time-item">
          <span className="value">{twoZeroes(minutes)}</span>
          <span className="label">{dictionary[`minute${minutes > 1 ? 's' : ''}`]}</span>
        </div>
        <div className="time-item">
          <span className="value">{twoZeroes(seconds)}</span>
          <span className="label">{dictionary[`second${seconds > 1 ? 's' : ''}`]}</span>
        </div>
      </StyledCountdown>
    );
  }

  return (
    <StyledCountdown className="countdown small">
      {label && <span className="label">{label}</span>}
      <div className="timer">
        {days > 0 && (
          <>
            <span className="days">
              {days} {dictionary[`day${days > 1 ? 's' : ''}`]}
            </span>{' '}
          </>
        )}
        <span className="clock">
          {hours > 0 ? `${twoZeroes(hours)}:` : ''}
          {twoZeroes(minutes)}:{twoZeroes(seconds)}
        </span>
      </div>
    </StyledCountdown>
  );
};

const StyledCountdown = styled.div`
  &.big {
    display: flex;
    justify-content: space-around;

    .time-item {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      min-width: 60px;

      .value {
        font-size: 4.6rem;
      }

      .label {
        font-size: 0.8rem;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  // Small til produktkort
  &.small {
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0.4rem 0 0 0;
    }

    .message {
      text-transform: uppercase;
      margin: 0.2rem 0;
    }
    .timer {
      display: flex;
      font-size: 2.2rem;
      color: $green;
      font-weight: 700;
      margin: 0.4rem 0;

      .days {
        margin-right: 10px;
      }
    }
  }
`;

export default Countdown;
