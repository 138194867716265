import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import TrimmedDataImage from './trimmed-data-image';
import { useOrderStore } from 'data/orders';
import { useRouter } from 'next/router';
import Loader from 'components/core/load-spinner';
import Select from 'components/formitems/select';
import { mqUntil } from 'styles/styles';
import { ContextProps, GlobalContext } from 'context/global-context';
import { ShippingDropPoint } from 'r2d2';

export type ShippingItemProps = {
  selected: boolean;
  name: string;
  logo: string;
  price: string;
  carrierName: string;
  description?: string;
  shippingId: number;
  droppoints?: ShippingDropPoint[];
  selectedDroppointId?: string | null;
  orderId?: string;
  onLoading: (loading: boolean) => void;
};

type ShippingDropPointWithAddress = ShippingDropPoint & { fullAddress: string };

export const ShippingItem: FC<ShippingItemProps> = ({
  selected,
  name,
  logo,
  price,
  description,
  carrierName,
  shippingId,
  selectedDroppointId,
  droppoints,
  orderId,
  onLoading,
}) => {
  const options: ShippingDropPointWithAddress[] =
    droppoints?.map((droppoint) => ({
      ...droppoint,
      fullAddress: `${droppoint.name}, ${droppoint.address}, ${droppoint.zipcode} ${droppoint.city}`,
    })) || [];

  const { dictionary } = useContext<ContextProps>(GlobalContext);
  const [loading, setLoading] = useState(false);
  const setShipping = useOrderStore((state) => state.setShipping);
  const [selectedDroppoint, setSelectedDroppoint] = useState<ShippingDropPointWithAddress | null>(null);
  const { locale, asPath: path } = useRouter();

  useEffect(() => {
    if (options.length) {
      if (selectedDroppointId) {
        const selectedOption = options.find((opt) => opt.id === selectedDroppointId) || null;
        setSelectedDroppoint(selectedOption);
      } else if (selectedDroppoint) {
        setSelectedDroppoint(null);
      }
    }
  }, [selectedDroppointId]); // eslint-disable-line

  const setShippingMethod = async ({
    shippingId,
    droppointId,
  }: {
    shippingId: number;
    droppointId?: string | null;
  }): Promise<void> => {
    setLoading(true);
    onLoading(true);
    await setShipping(
      {
        shippingId,
        droppointId,
        orderId,
      },
      {
        locale: locale!,
        path,
      },
    );

    onLoading(false);
    setLoading(false);
  };

  return (
    <StyledShippingItem
      className={classNames('item shipping-item', { selected })}
      onClick={(e) => {
        // Klik ikke er inde i Select boksen
        if (!(e.target as HTMLDivElement).closest('.select')) {
          setShippingMethod({
            shippingId: shippingId,
            droppointId: null,
          });
        }
      }}
    >
      {loading ? <Loader size={32} /> : <div className="radio" />}
      <div className="info">
        <h3 className="light h4">{name}</h3>
        {logo.startsWith('data:image') && <TrimmedDataImage base64={logo} className="logo" alt={carrierName} />}
        {logo && !logo.startsWith('data:image') && <div className="logo-tag">{logo}</div>}
        {description && <div className="address">{description}</div>}
        {!!droppoints?.length && selectedDroppoint && (
          <div className="droppoints">
            <div className="selected">
              <label htmlFor="">{dictionary?.shippingChosenDroppoint}</label>
              {selectedDroppoint.name}
              <br /> {selectedDroppoint.address}
              <br /> {selectedDroppoint.zipcode} {selectedDroppoint.city}
            </div>
          </div>
        )}
      </div>
      <div className="price h3">{price}</div>
      {!!droppoints?.length && (
        <Select
          onChange={(e) => {
            const selectedOption = options.find((item) => item.fullAddress === e.target.value);
            // setSelectedDroppoint(selectedOption as DeliveryShopWithAddress);
            setShippingMethod({
              shippingId: shippingId,
              droppointId: selectedOption!.id,
            });
          }}
          name={'droppoint'}
          value={selectedDroppoint?.fullAddress}
          placeholder={dictionary?.shippingChooseDroppoint}
          options={options.map((item) => item.fullAddress)}
        />
      )}
    </StyledShippingItem>
  );
};

export const StyledShippingItem = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr auto;
  gap: 0.7rem;
  cursor: pointer;

  .radio {
    margin: 0 1rem 0 0;

    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: var(--light-background);
  }

  .load-wrapper {
    margin: 0 1rem 0 0;
    width: 2rem;

    align-items: flex-start;
  }

  .info {
    padding: 0.2rem 0;

    h3 {
      margin: 0 0 0.3rem 0;
    }

    .logo {
      max-height: 12px;
      width: auto;
      margin: 0.4rem 0;
    }

    .address {
      color: var(--base-dimmed-foreground);
      margin: 0.3rem 0;
    }
  }

  .price {
    margin: 0;
    padding: 0.2rem 0;
  }

  .select {
    grid-column-start: 2;
    grid-column-end: 4;

    ${mqUntil.desktop} {
      grid-column-start: 1;
    }
  }
  &.selected {
    background: var(--ultra-light-background);
    .radio {
      border: 0.7rem solid var(--secondary-background);
      /* background: white; */
    }
  }

  &:hover {
    background: var(--ultra-light-background);
  }

  .logo-tag {
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 800;
    margin: 0.2rem 0;
    padding: 0.2rem 0.5rem;
    border-radius: 2px;
    background: var(--secondary-background);
    color: var(--secondary-foreground);
    opacity: 0.8;
  }

  .droppoints {
    margin: 1rem 0 0.5rem 0;

    .selected {
      font-size: 0.8rem;
      font-weight: 600;
      margin: 0 0 1.5rem 0;
      label {
        display: block;
        font-weight: 400;
        color: var(--base-dimmed-foreground);
      }
    }
  }
`;

export default ShippingItem;
