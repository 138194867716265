import { FC, useEffect } from 'react';

import styled from 'styled-components';

export type ViabillProps = {
  view: string;
  price: number;
};

export const Viabill: FC<ViabillProps> = ({ view, price }) => {
  const viabill = (): void => {
    if (window && window.vb) {
      window.vb.buildTags();
    } else {
      const script = document.createElement('script');
      script.src = 'https://pricetag.viabill.com/script/75GWn28y2Xc%3D';
      script.async = true;
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    viabill();
  }, [price, view]);

  // Læs mere her: https://viabill.io/api/pricetag/#plugins
  return (
    <StyledViabill>
      <div className="viabill" id="viabill">
        <div
          className="viabill-pricetag viabill-pricetag-optional-styles1"
          data-view={view}
          data-price={price.toFixed(2)}
          data-currency="dkk"
          data-language="da"
          data-country-code="DK"
          data-product-type="light"
        />
      </div>
    </StyledViabill>
  );
};

export const StyledViabill = styled.div``;

export default Viabill;
