import create, { State } from 'zustand';
import { getToken } from 'utils/helpers';
import { captureException } from 'utils/sentry';
// import { diffString, diff } from 'json-diff';
import {
  ChangeQuantityErrorData,
  ChangeQuantitySuccessData,
  ApiResponse,
  CheckReservationSuccessData,
  ConsentSettings,
  Order,
  GetCustomerSuccessData,
  GetShippingMethodsSuccessData,
  GetCountriesSuccessData,
  SetShippingSuccessData,
  GetPaymentLinkSuccessData,
  SetRefsSuccessData,
  SetVoucherSuccessData,
  SetCurrencySuccessData,
  SetCookieConsentSuccessData,
  SetCustomerErrorData,
  SetCustomerSuccessData,
  QueueCustomerErrorData,
  QueueCustomerSuccessData,
} from 'r2d2';

type MutationContext = {
  locale: string;
  path?: string;
};

type InitRefs = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  hltid?: string;
  // gclid?: string;
  ref?: string;
  affid?: string;
  campaign?: string;
  fbclid?: string;
};

type AddToBasketParams = {
  productId: string;
  amount: number;
  selected: Record<string, string>;
  customTextVariantValue?: string;
  customTextVariant2Value?: string;
};

type CheckReservationsParams = {
  reservationId: string;
  exactSku: string;
};

type ChangeLineProps = { lineId: string; amount: number };

type SetCustomerCountryProps = { country: string };

type SetShippingProps = {
  shippingId: number;
  droppointId?: string | null;
  orderId?: string | null;
};

type SetVoucherProps = { code: string };

type ChangeCurrencyProps = { currency: string };

type SetCookieConsentProps = { settings: ConsentSettings };

interface OrderState extends State {
  fetched: boolean;
  order?: Order | null;
  getCustomerOrder: (ctx: MutationContext) => Promise<void>;
  clearReservations: (ctx: MutationContext) => Promise<void>;
  setRefs: (refs: InitRefs, ctx: MutationContext) => Promise<void>;
  addToBasket: (
    props: AddToBasketParams,
    ctx: MutationContext,
  ) => Promise<ApiResponse<ChangeQuantitySuccessData, ChangeQuantityErrorData>>;
  checkReservation: (
    props: CheckReservationsParams,
    ctx: MutationContext,
  ) => Promise<ApiResponse<CheckReservationSuccessData, null>>;
  changeQuantityOnLine: (
    props: ChangeLineProps,
    ctx: MutationContext,
  ) => Promise<ApiResponse<ChangeQuantitySuccessData, ChangeQuantityErrorData>>;
  setCustomerInfo: (
    props: Record<string, unknown>,
    ctx: MutationContext,
  ) => Promise<ApiResponse<SetCustomerSuccessData, SetCustomerErrorData>>;
  setCustomerCountry: (
    props: SetCustomerCountryProps,
    ctx: MutationContext,
  ) => Promise<ApiResponse<SetCustomerSuccessData, SetCustomerErrorData>>;
  setShipping: (props: SetShippingProps, ctx: MutationContext) => Promise<ApiResponse<SetShippingSuccessData, null>>;
  setVoucher: (props: SetVoucherProps, ctx: MutationContext) => Promise<ApiResponse<SetVoucherSuccessData, null>>;
  setCookieConsent: (
    props: SetCookieConsentProps,
    ctx: MutationContext,
  ) => Promise<ApiResponse<SetCookieConsentSuccessData, null>>;
  changeCurrency: (
    props: ChangeCurrencyProps,
    ctx: MutationContext,
  ) => Promise<ApiResponse<SetCurrencySuccessData, null>>;
  createOrderAndGoToQueue: (
    ctx: MutationContext,
  ) => Promise<ApiResponse<QueueCustomerSuccessData, QueueCustomerErrorData>>;
}

export const useOrderStore = create<OrderState>((set, get) => ({
  fetched: false,
  order: null,
  getCustomerOrder: async ({ locale, path }: MutationContext) => {
    const checkoutData = await getCheckoutData({ locale, path });

    if (checkoutData.success) {
      set({ fetched: true, order: checkoutData.data.order });
    }
  },
  clearReservations: async ({ locale, path }: MutationContext) => {
    const checkoutData = await clearReservations({ locale, path });

    if (checkoutData.success) {
      set({ fetched: true, order: checkoutData.data.order });
    }
  },
  setRefs: async (refs: InitRefs, { locale, path }: MutationContext) => {
    const res = await setRefs(refs, { locale, path });
    if (!res.success) {
      captureException('setRefs failed', { res, locale, path, refs });
    }
  },
  addToBasket: async (
    { productId, amount, selected, customTextVariantValue, customTextVariant2Value }: AddToBasketParams,
    { locale, path }: MutationContext,
  ) => {
    const { order } = get();

    const res = await addToBasket(
      { productId, amount, selected, customTextVariantValue, customTextVariant2Value },
      { locale, path },
    );
    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    }
    return res;
  },
  checkReservation: async ({ reservationId, exactSku }: CheckReservationsParams, { locale, path }: MutationContext) => {
    const { order } = get();

    const res = await checkReservation({ reservationId, exactSku }, { locale, path });

    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    }
    return res;
  },
  changeQuantityOnLine: async ({ lineId, amount }: ChangeLineProps, { locale, path }: MutationContext) => {
    const { order } = get();
    const res = await changeQuantityOnLine({ lineId, amount }, { locale, path });

    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    }
    return res;
  },
  setCustomerInfo: async ({ ...info }: Record<string, unknown>, { locale, path }: MutationContext) => {
    const { order } = get();
    // Optimistic UI
    set({ order: { ...(order as Order), ...info } });
    const res = await setCustomerInfo({ ...info }, { locale, path });

    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    } else {
      captureException('setCustomerInfo failed', {
        res,
        locale,
        path,
        info,
      });
    }
    return res;
  },
  setCustomerCountry: async ({ country }: SetCustomerCountryProps, { locale, path }: MutationContext) => {
    const { order } = get();
    // Optimistic UI
    set({ order: { ...(order as Order), country } });
    const res = await setCustomerInfo({ country }, { locale, path });

    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    } else {
      captureException('setCustomerCountry failed', {
        res,
        locale,
        path,
        country,
      });
    }
    return res;
  },
  setShipping: async (
    { shippingId, droppointId = null, orderId = null }: SetShippingProps,
    { locale, path }: MutationContext,
  ) => {
    const { order } = get();
    const res = await setShipping({ shippingId, droppointId, orderId }, { locale, path });

    if (res.success) {
      if (res.data.order) {
        set({ order: { ...order, ...res.data.order } });
      }
    } else {
      captureException('setShipping failed', {
        res,
        locale,
        path,
        shippingId,
        droppointId,
        orderId,
      });
    }
    return res;
  },
  setVoucher: async ({ code }: SetVoucherProps, { locale, path }: MutationContext) => {
    const { order } = get();
    const res = await setVoucher({ code }, { locale, path });
    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    } else {
      captureException('setVoucher failed', {
        res,
        locale,
        path,
        code,
      });
    }
    return res;
  },
  setCookieConsent: async ({ settings }: SetCookieConsentProps, { locale, path }: MutationContext) => {
    const { order } = get();
    const res = await setCookieConsent({ settings }, { locale, path });
    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    } else {
      captureException('setCookieConsent failed', {
        res,
        locale,
        path,
        settings,
      });
    }
    return res;
  },
  changeCurrency: async ({ currency }, { locale, path }: MutationContext) => {
    const { order } = get();
    const res = await changeCurrency({ currency }, { locale, path });

    if (res.success) {
      set({ order: { ...order, ...res.data.order } });
    } else {
      captureException('changeCurrency failed', {
        res,
        locale,
        path,
        currency,
      });
    }
    return res;
  },
  createOrderAndGoToQueue: async ({ locale, path }: MutationContext) => {
    const res = await insertCustomerIntoPaymentQueue({ locale, path });

    return res;
  },
}));

async function getCheckoutData({ locale, path }: MutationContext): Promise<ApiResponse<GetCustomerSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('getCheckoutData called from server');
  }

  const orderDataNew = await fetch('/api/checkout/get-customer-order', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
    }),
  });
  const data = (await orderDataNew.json()) as ApiResponse<GetCustomerSuccessData, null>;

  return data;
}
async function clearReservations({
  locale,
  path,
}: MutationContext): Promise<ApiResponse<GetCustomerSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('getCheckoutData called from server');
  }

  const orderDataNew = await fetch('/api/checkout/clear-reservations', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
    }),
  });

  const data = (await orderDataNew.json()) as ApiResponse<GetCustomerSuccessData, null>;

  return data;
}

export async function getShippingMethods({
  locale,
  path,
}: MutationContext): Promise<ApiResponse<GetShippingMethodsSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('getShippingMethods called from server');
  }

  const shippingDataRes = await fetch('/api/shipping/get-shippingmethods', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
    }),
  });

  const shippingData = (await shippingDataRes.json()) as ApiResponse<GetShippingMethodsSuccessData, null>;

  return shippingData;
}

export async function getCountries({
  locale,
  path,
}: MutationContext): Promise<ApiResponse<GetCountriesSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('getCountries called from server');
  }
  const countriesDataRes = await fetch('/api/shipping/get-countries', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
    }),
  });
  const countriesData = (await countriesDataRes.json()) as ApiResponse<GetCountriesSuccessData, null>;
  return countriesData;
}

async function addToBasket(
  { productId, amount, selected, customTextVariantValue, customTextVariant2Value }: AddToBasketParams,
  { locale, path }: MutationContext,
): Promise<ApiResponse<ChangeQuantitySuccessData, ChangeQuantityErrorData>> {
  if (typeof window === 'undefined') {
    throw new Error('addToBasket called from server');
  }
  const addToBasketAndGetOrder = await fetch('/api/checkout/add-to-basket', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      productId,
      amount,
      selected,
      customTextVariantValue,
      customTextVariant2Value,
    }),
  });
  const data = (await addToBasketAndGetOrder.json()) as ApiResponse<ChangeQuantitySuccessData, ChangeQuantityErrorData>;

  return data;
}

async function checkReservation(
  { reservationId, exactSku }: CheckReservationsParams,
  { locale, path }: MutationContext,
): Promise<ApiResponse<CheckReservationSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('checkReservation called from server');
  }
  const checkReservation = await fetch('/api/checkout/check-reservation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      reservationId,
      exactSku,
    }),
  });
  const data = (await checkReservation.json()) as ApiResponse<CheckReservationSuccessData, null>;

  return data;
}

async function changeQuantityOnLine(
  { lineId, amount }: ChangeLineProps,
  { locale, path }: MutationContext,
): Promise<ApiResponse<ChangeQuantitySuccessData, ChangeQuantityErrorData>> {
  if (typeof window === 'undefined') {
    throw new Error('changeQuantityOnLine called from server');
  }

  const changeQuantityOnLineRes = await fetch('/api/checkout/change-quantity', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      lineId,
      amount,
    }),
  });
  const data = (await changeQuantityOnLineRes.json()) as ApiResponse<
    ChangeQuantitySuccessData,
    ChangeQuantityErrorData
  >;

  return data;
}

async function setRefs(
  refs: InitRefs,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetRefsSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('setRefs called from server');
  }

  const res = await fetch('/api/checkout/set-refs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      refs,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetRefsSuccessData, null>;

  return data;
}

async function setCustomerInfo(
  { validate = false, ...info }: Record<string, unknown>,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetCustomerSuccessData, SetCustomerErrorData>> {
  if (typeof window === 'undefined') {
    throw new Error('setRefs called from server');
  }

  const res = await fetch('/api/checkout/set-customer-info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      validate,
      customerInfo: info,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetCustomerSuccessData, SetCustomerErrorData>;

  return data;
}

async function setShipping(
  { shippingId, droppointId = null, orderId = null }: SetShippingProps,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetShippingSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('setShipping called from server');
  }

  const res = await fetch('/api/shipping/set-shippingmethod', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      shippingId,
      droppointId,
      orderId,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetShippingSuccessData, null>;

  return data;
}
async function setVoucher(
  { code }: SetVoucherProps,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetVoucherSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('setVoucher called from server');
  }

  const res = await fetch('/api/checkout/set-voucher', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      code,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetVoucherSuccessData, null>;

  return data;
}

async function changeCurrency(
  { currency }: ChangeCurrencyProps,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetCurrencySuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('changeCurrency called from server');
  }

  const res = await fetch('/api/checkout/set-currency', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      currency,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetCurrencySuccessData, null>;

  return data;
}

async function setCookieConsent(
  { settings }: SetCookieConsentProps,
  { locale, path }: MutationContext,
): Promise<ApiResponse<SetCookieConsentSuccessData, null>> {
  if (typeof window === 'undefined') {
    throw new Error('changeCurrency called from server');
  }

  const res = await fetch('/api/checkout/set-cookie-consent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      settings,
    }),
  });
  const data = (await res.json()) as ApiResponse<SetCookieConsentSuccessData, null>;

  return data;
}

async function insertCustomerIntoPaymentQueue({
  locale,
  path,
}: MutationContext): Promise<ApiResponse<QueueCustomerSuccessData, QueueCustomerErrorData>> {
  if (typeof window === 'undefined') {
    throw new Error('insertCustomerIntoPaymentQueue called from server');
  }

  const res = await fetch('/api/checkout/queue-customer', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
    }),
  });
  const data = (await res.json()) as ApiResponse<QueueCustomerSuccessData, QueueCustomerErrorData>;

  return data;
}

export const getPaymentLinkWhenReady = async (
  { orderId }: { orderId: string },
  { locale, path }: MutationContext,
): Promise<ApiResponse<GetPaymentLinkSuccessData, null>> => {
  if (typeof window === 'undefined') {
    throw new Error('insertCustomerIntoPaymentQueue called from server');
  }

  const paymentReadyRes = await fetch('/api/payment/get-paymentlink', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      path,
      orderId,
    }),
  });

  const paymentReady = (await paymentReadyRes.json()) as ApiResponse<GetPaymentLinkSuccessData, null>;

  return paymentReady;
};
