import { useState, useRef, useEffect, useCallback } from 'react';
import { deviceSizes } from 'styles/styles';

export const useScroll = (
  threshold: number,
): {
  scrollX: number;
  scrollY: number;
  isOnTop: boolean;
} => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const listener = (): void => {
    setScrollY(window.scrollY);
    setScrollX(window.scrollX);
  };

  useEffect(() => {
    setScrollY(window.scrollY);
    setScrollX(window.scrollX);

    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  return {
    scrollY,
    scrollX,
    isOnTop: scrollY <= threshold,
  };
};

export const useWindowSize = (): {
  width: number;
  height: number;
  device: string;
} => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const listener = (): void => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  let device = '';
  if (width > 0) {
    device = 'mobile';

    if (width > deviceSizes.tablet) {
      device = 'tablet';
    }
    if (width > deviceSizes.desktop) {
      device = 'desktop';
    }
    if (width > deviceSizes.wide) {
      device = 'wide';
    }
  }

  return {
    width,
    height,
    device,
  };
};

export const useRecursiveTimeout = (callback: () => void, delay: number) => {
  // eslint-disable-line
  const [isRunning, setIsRunning] = useState(false);
  const stop = useCallback(() => setIsRunning(false), [setIsRunning]);
  const play = useCallback(() => setIsRunning(true), [setIsRunning]);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isRunning) return;
    let id: number;

    const tick = (): void => {
      if (!isRunning) return clearTimeout(id);
      savedCallback.current();
      requestAnimationFrame(() => (id = setTimeout(tick, delay) as unknown as number));
    };
    requestAnimationFrame(() => (id = setTimeout(tick, delay) as unknown as number));

    return () => {
      if (id) clearTimeout(id);
      stop();
    };
  }, [isRunning, delay, stop]);

  return { play, stop };
};

export const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef<() => void | null>();
  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });
  // Set up the interval.
  useEffect(() => {
    function tick() {
      // eslint-disable-line
      if (typeof savedCallback?.current !== 'undefined') {
        savedCallback?.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
