/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { ContextProps, GlobalContext } from 'context/global-context';
import { mqUntil } from 'styles/styles';
import { isExternalLink } from 'utils/helpers';
import { SimplePage } from 'r2d2';

type ItemProps = {
  item: SimplePage;
};

type StyledProps = {
  longList: boolean;
};

type CascadeNavigationProps = {}; // eslint-disable-line

const CascadeNavigation: React.FC<CascadeNavigationProps> = () => {
  const { pages, dictionary } = useContext<ContextProps>(GlobalContext);
  const [selected, setSelected] = useState<string | null>(null);

  const NavLink: React.FC<ItemProps> = ({ item }) => {
    const router = useRouter();
    const path = router.asPath.split('?')[0];

    if (item.children?.length) {
      return (
        <button type="button" onClick={() => setSelected(item._id)}>
          {item.name}
          <FiChevronRight />
        </button>
      );
    }
    if (item.type === 'link') {
      const addOns = isExternalLink(item?.href || '') ? { target: '_BLANK', rel: 'noopener noreferrer' } : {};
      return (
        <a href={item?.href} {...addOns}>
          {item.name}
        </a>
      );
    }
    return (
      <Link
        prefetch={false}
        href={item.href}
        className={`${
          item.href === path.split('?')[0] || (item.children || []).find((c) => c.href === path.split('?')[0])
            ? 'active'
            : ''
        }`}
      >
        {item.name}
      </Link>
    );
  };

  const allPages: (SimplePage & { subId: string | null })[] = [];

  const addPages = (items: SimplePage[], subId: string | null): void => {
    items.forEach((item) => {
      allPages.push({ subId, ...item });
      if (item.children?.length) {
        addPages(item.children, item._id);
      }
    });
  };

  addPages(pages || [], null);

  const listPages = allPages.filter((item) => !item.hideInNavigation).filter((item) => item.subId === selected);

  return (
    <CascadeNavigationStyled className="cascade" longList={listPages.length > 10}>
      {selected && (
        <button onClick={() => setSelected(null)} type="button" className="back">
          <FiChevronLeft /> {dictionary?.goBack}
        </button>
      )}
      <ul>
        {listPages.map((item) => (
          <li key={item._id}>
            <NavLink item={item} />
          </li>
        ))}
      </ul>
    </CascadeNavigationStyled>
  );
};

const CascadeNavigationStyled = styled.nav<StyledProps>`
  /* transition: height 0.2s ease-in-out; */

  .back {
    width: 100%;
    padding: 0.3rem 1rem;
    text-decoration: none;
    font-size: 1rem;
    font-family: var(--font-family-display);
    font-weight: 600;
    position: relative;
    display: flex;
    justify-content: stretch;
    align-items: center;

    &:hover,
    &.active {
      background: var(--sidebar-hover);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      display: block;

      > a,
      > button {
        display: flex;
        justify-content: stretch;
        align-items: center;
        width: 100%;
        color: var(--base-foreground);
        padding: 0.3rem 1rem;
        flex: 1 1 0;
        text-decoration: none;
        font-size: ${(props) => (props.longList ? '1.4rem' : '2rem')};
        font-family: var(--font-family-display);
        font-weight: 700;
        position: relative;

        ${mqUntil.mobile} {
          font-size: 1rem;
        }

        &:hover,
        &.active {
          background: var(--sidebar-hover);
          color: var(--primary-background);
        }
      }
    }
  }
`;

export default CascadeNavigation;
