/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
// import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useErrorBoundary } from 'use-error-boundary';
import { useRouter } from 'next/router';
import SidebarNavigation from 'components/core/sidebar-navigation';
import { GlobalStyle } from 'styles/styles';
import { buildImgixUrl } from 'utils/helpers';
import { GlobalContext, PopupStates, TrackingStates } from '../context/global-context';
import { useWindowSize } from '../utils/hooks';
import { captureException } from '../utils/sentry';
import { useOrderStore } from '../data/orders';
import { getShopSettings } from 'data/shop';
import Tracking from 'components/core/tracking';
// import Crisp from 'components/core/crisp';
import SidebarCheckout from 'components/checkout/sidebar-checkout';
import { CurrencyKeys, Shop, ShopResponse, SimplePage } from 'r2d2';
import Script from 'next/script';

type AppWrapperProps = {
  shop: ShopResponse;
  pages: SimplePage[];
  children: ChildrenProperty;
};

export const AppWrapper: React.FC<AppWrapperProps> = ({ children, shop: shopResponse, pages = [] }) => {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary();
  const [trackingState, setTrackingState] = useState<TrackingStates>('none');
  const [popupState, setPopupState] = useState<PopupStates>('none');
  const [currentCurrency, setCurrentCurrency] = useState<CurrencyKeys>(shopResponse.defaultCurrency);
  // const [currentCurrencyRate, setCurrentCurrencyRate] = useState<number>(1);
  const [shopData, setShopData] = useState<ShopResponse>(shopResponse);

  const { device } = useWindowSize();
  const getCustomerOrder = useOrderStore((state) => state.getCustomerOrder);

  const router = useRouter();
  const { locale, asPath, locales, defaultLocale } = router;

  useEffect(() => {
    const fetchShopSettings = async (): Promise<void> => {
      const updatedSettings = await getShopSettings(router.locale!);
      if (updatedSettings?.slug === process.env.NEXT_PUBLIC_SHOP_SLUG) {
        setShopData({ ...shopData, ...updatedSettings } as ShopResponse);
      }
    };

    // Fetch colors and settings on init
    if (shopResponse.devMode || shopResponse.workingMode) {
      fetchShopSettings();
    }
    // Fetch order on
    getCustomerOrder({ locale: locale!, path: asPath });
  }, [locale]); // eslint-disable-line

  const frontpage = ['', '/index', '/'].includes(asPath);

  useEffect(() => {
    if (didCatch) {
      captureException(error);
    }
  }, [didCatch, error]);

  useEffect(() => {
    const bodyClasses = Object.values(router.query);
    bodyClasses.push(process.env.NEXT_PUBLIC_SHOP_SLUG);
    bodyClasses.push(device);
    if (frontpage) {
      bodyClasses.push('frontpage');
    } else if (router.query.productSlug) {
      bodyClasses.push('product');
    } else {
      bodyClasses.push('page');
    }
    document.querySelector('body')!.className = bodyClasses.join(' ');
  }, [router.asPath, router.query, device, frontpage]);

  const { dictionary, theme, customStyles, customScripts, backgroundImageDesktop, backgroundImageMobile, ...shop } =
    shopData;

  return (
    <GlobalContext.Provider
      value={{
        dictionary,
        shop: shop as Shop,
        theme,
        popupState,
        setPopupState,
        pages,
        device,
        trackingState,
        setTrackingState,
        currentCurrency,
        setCurrentCurrency,
        // currentCurrencyRate,
        // setCurrentCurrencyRate,
      }}
    >
      {didCatch ? (
        <p>An error has been catched: {error.message}</p>
      ) : (
        <ErrorBoundary>
          <Head>
            <meta name="robots" content={shop.devMode ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW'} />
            {shop.favicon && <link rel="icon" type="image/x-icon" href={buildImgixUrl(shop.favicon?.src)} />}
            {shop.favicon && <link rel="shortcut icon" type="image/x-icon" href={buildImgixUrl(shop.favicon?.src)} />}
            {theme.settings.fontImport?.includes('typekit') && !theme.settings.fontImport?.includes('@import') && (
              <link rel="stylesheet" href={theme.settings.fontImport} />
            )}
            {theme.settings.fontImport?.includes('googleapis') && !theme.settings.fontImport?.includes('@import') && (
              <>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <link rel="stylesheet" href={theme.settings.fontImport} />
              </>
            )}
            {shop.tracking?.googleVerificationCode && (
              <meta name="google-site-verification" content={shop.tracking.googleVerificationCode} />
            )}
            {shop.tracking?.facebookVerificationCode && (
              <meta name="facebook-domain-verification" content={shop.tracking.facebookVerificationCode} />
            )}
            {shop?.tracking.wishcloudButtonKey && (
              <Script src={`https://tag.heylink.com/${shop?.tracking.wishcloudButtonKey}/script.js`} defer></Script>
            )}
            {!theme?.settings?.disableCrisp && (
              <script src="https://cdn.herodesk.io/livechat.js?wid=e5ef8a29b3462c"></script>
            )}

            <meta property="og:site_name" content={shop.domain} />
            <meta property="og:site" content={`https://${shop.domain}/`} />
            {locales!.map((loc) => {
              return (
                <link
                  key={loc}
                  rel="alternate"
                  hrefLang={loc}
                  href={`https://${shop.domain}/${loc !== defaultLocale ? loc : ''}`}
                />
              );
            })}
            <link
              rel="alternate"
              href={`https://${shop.domain}/${defaultLocale !== 'en' ? 'en' : ''}`}
              hrefLang="x-default"
            />
          </Head>

          <GlobalStyle
            settings={theme.settings}
            colors={theme.colors}
            customStyles={customStyles}
            popupState={popupState}
            backgroundImageDesktop={backgroundImageDesktop}
            backgroundImageMobile={backgroundImageMobile}
          />
          <Tracking shop={shopData} />
          <a className="skip-content" href="#MainContent">
            Skip to content
          </a>
          <div className={`app`}>
            {children}
            <SidebarNavigation />
            <SidebarCheckout />
          </div>
          {/* <Crisp shop={shopData} /> */}
          {customScripts && (
            <script
              id="customScript"
              type="text/javascript"
              dangerouslySetInnerHTML={{ __html: customScripts }}
              async
            />
          )}
        </ErrorBoundary>
      )}
    </GlobalContext.Provider>
  );
};
