import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { ContextProps, GlobalContext } from '../../context/global-context';
import Input from '../formitems/input';
import Select from '../formitems/select';
import Button from '../formitems/button';
import Checkbox from '../formitems/checkbox';
import { getCountries, useOrderStore } from '../../data/orders';
import { isEmail } from 'utils/helpers';
import { getCityForZipcode } from 'data/zipcodes';
import { Dictionary } from 'r2d2';

export type CheckoutFormProps = {
  setStep: (number: number) => void;
  noGoingBack?: boolean;
};

type FormValues = {
  email: string;
  newsletter: boolean;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  zipcode: string;
  city: string;
  country: string;
  phone: string;
  conditions: boolean;
  message: string;
};

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ setStep, noGoingBack }) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  const customer = useOrderStore((state) => {
    if (!state.fetched) {
      return null;
    }

    return {
      email: state.order?.email || '',
      newsletter: state.order?.newsletter || false,
      firstName: state.order?.firstName || '',
      lastName: state.order?.lastName || '',
      address: state.order?.address || '',
      address2: state.order?.address2 || '',
      zipcode: state.order?.zipcode || '',
      city: state.order?.city || '',
      country: state.order?.country || 'Danmark',
      phone: state.order?.phone || '',
      conditions: state.order?.conditions || false,
      message: state.order?.message || '',
    };
  });

  const [showConditions, setShowConditions] = useState((customer && !customer.conditions) || false);

  // Sæt til false igen hvis vi skal bruge en skjult besked
  const [showMessage, setShowMessage] = useState(customer?.message || true);

  const router = useRouter();
  const { validationInvalidEmail, validationRequired, validationSelectCountry } = dictionary as Dictionary;
  const [submitting, setSubmitting] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [countries, setCountries] = useState<string[]>([]);
  const setCustomerInfo = useOrderStore((state) => state.setCustomerInfo);
  const setCustomerCountry = useOrderStore((state) => state.setCustomerCountry);

  const ctx = {
    locale: router.locale!,
    path: router.asPath,
  };

  useEffect(() => {
    const fetchCountries = async (): Promise<void> => {
      const getCountriesData = await getCountries(ctx);

      if (getCountriesData.success) {
        setCountries(getCountriesData.data.countries);
      }
      if (getCountriesData.success === false) {
        setCountries([]);
        toast(
          // @ts-ignore - Den siger at data er never
          getCountriesData.data?.message ||
            // @ts-ignore - Den siger at data er never
            getCountriesData.message ||
            'Unkown reservation error',
          {
            type: 'error',
          },
        );
      }
    };
    fetchCountries();
  }, [router.locale]); // eslint-disable-line

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    watch,
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { zipcode, country } = watch();

  useEffect(() => {
    checkZipcode(zipcode, country);
  }, [zipcode, country]); // eslint-disable-line

  useEffect(() => {
    if (customer?.message && !showMessage) {
      setShowMessage(true);
    }
  }, [customer?.message]); // eslint-disable-line

  const checkZipcode = (zipcode: string | null, country: string | null): void => {
    const city = getCityForZipcode(zipcode, country);

    if (city) {
      setValue('city', city);
      setDisableCity(true);
    } else {
      setDisableCity(false);
    }
  };

  if (!customer) {
    return null;
  }

  const onCountryChange = async (value: string): Promise<void> => {
    console.log('onCountryChange', value);

    await setCustomerCountry({ country: value }, ctx);
  };

  const onSubmit = async (inputdata: Record<string, unknown>): Promise<void> => {
    setSubmitting(true);
    const res = await setCustomerInfo({ ...inputdata, validate: true }, ctx);

    if (res.success) {
      setSubmitting(false);
      setStep(2);
    } else {
      toast(res.data.message, { type: 'error' });
      if (res.data.errors?.length) {
        const errors = res.data.errors;
        errors.forEach((error) => {
          if (error.type === 'checkout') {
            console.log('// set error', error.field, error.message);

            if (error.field === 'conditions') {
              setShowConditions(true);
            }

            setError(error.field as keyof FormValues, {
              type: 'manual', // error.errorKey === 'required' ? 'required' : 'validate',
              message: error.message,
            });
          } else {
            toast(error.message, { type: 'error' });
          }
        });
      }
      if ((res.data as ResponseData)?.goto === 'basket') {
        setStep(0);
      }

      setSubmitting(false);
    }
  };

  return (
    <StyledCheckoutForm className="checkout-form-wrapper">
      <h3>{dictionary?.yourInformation}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-item">
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={value}
                placeholder={dictionary?.email}
                errorMessage={errors.email?.message || ''}
                autoComplete="shipping email"
              />
            )}
            name="email"
            rules={{
              required: validationInvalidEmail,
              validate: (val) => {
                if (isEmail(val)) {
                  return true;
                }
                return dictionary?.validationInvalidEmail;
              },
            }}
            control={control}
            defaultValue={customer.email}
          />
        </div>

        <div className="form-item newsletter">
          <Controller
            render={({ field: { value, name } }) => (
              <Checkbox
                onChange={(e) => {
                  setValue(name, e.target.checked);
                }}
                name={name}
                checked={value || false}
                label={dictionary!.acceptNewsletter}
              />
            )}
            name="newsletter"
            control={control}
            defaultValue={customer.newsletter || false}
          />
        </div>
        <h3>Leveringsoplysninger</h3>

        <div className="side-by-side">
          <div className="form-item">
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <Input
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary?.firstName}
                  errorMessage={errors.firstName?.message || ''}
                  autoComplete="shipping given-name"
                />
              )}
              name="firstName"
              rules={{ required: validationRequired }}
              control={control}
              defaultValue={customer.firstName}
            />
          </div>
          <div className="form-item">
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <Input
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary?.lastName}
                  errorMessage={errors.lastName?.message || ''}
                  autoComplete="shipping family-name"
                />
              )}
              name="lastName"
              rules={{ required: validationRequired }}
              control={control}
              defaultValue={customer.lastName}
            />
          </div>
        </div>

        <div className="form-item">
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={value}
                placeholder={dictionary?.address}
                errorMessage={errors.address?.message || ''}
                autoComplete="shipping address-line1"
              />
            )}
            name="address"
            rules={{ required: 'Du skal' }}
            control={control}
            defaultValue={customer.address}
          />
        </div>
        <div className="form-item">
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={value}
                placeholder={dictionary?.address2}
                errorMessage={errors.address2?.message || ''}
                autoComplete="shipping address-line2"
              />
            )}
            name="address2"
            control={control}
            defaultValue={customer.address2}
          />
        </div>
        <div className="side-by-side">
          <div className="form-item">
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <Input
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary?.zipcode}
                  errorMessage={errors.zipcode?.message || ''}
                  autoComplete="shipping postal-code"
                />
              )}
              name="zipcode"
              rules={{ required: validationRequired }}
              control={control}
              defaultValue={customer.zipcode}
            />
          </div>
          <div className="form-item">
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <Input
                  disabled={disableCity}
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary?.city}
                  errorMessage={errors.city?.message || ''}
                  autoComplete="shipping address-level2"
                />
              )}
              name="city"
              rules={{ required: validationRequired }}
              control={control}
              defaultValue={customer.city}
            />
          </div>
        </div>
        <div className="form-item">
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <Select
                onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                  onChange(e);
                  onCountryChange(e.target.value);
                }}
                name={name}
                value={value}
                placeholder={dictionary?.country}
                errorMessage={errors.country?.message || ''}
                options={countries}
                autoComplete="shipping country-name"
              />
            )}
            name="country"
            rules={{ required: validationSelectCountry }}
            control={control}
            defaultValue={customer.country}
          />
        </div>

        <div className="form-item">
          <Controller
            render={({ field: { onChange, value, name } }) => (
              <Input
                onChange={onChange}
                name={name}
                value={value}
                placeholder={dictionary?.phone}
                errorMessage={errors.phone?.message || ''}
                autoComplete="shipping tel"
              />
            )}
            name="phone"
            rules={{ required: validationRequired }}
            control={control}
            defaultValue={customer.phone}
          />
        </div>

        {showConditions && (
          <div className="form-item">
            <Controller
              render={({ field: { value, name } }) => (
                <Checkbox
                  onChange={(e) => {
                    setValue(name, e.target.checked);
                  }}
                  name={name}
                  checked={value || false}
                  label={dictionary!.acceptConditions}
                  errorMessage={errors.conditions?.message}
                />
              )}
              name="conditions"
              control={control}
              defaultValue={customer.conditions || false}
            />
          </div>
        )}

        {showMessage ? (
          <div className="form-item message">
            <Controller
              render={({ field: { onChange, value, name } }) => (
                <Input
                  rows={2}
                  onChange={onChange}
                  name={name}
                  value={value}
                  placeholder={dictionary?.message}
                  errorMessage={errors.message?.message || ''}
                />
              )}
              name="message"
              control={control}
              defaultValue={customer.message}
            />
          </div>
        ) : (
          <Button onClick={() => setShowMessage(true)} size="small" transparent noPadding secondary>
            {dictionary?.showMessageInput}
          </Button>
        )}

        <div className="bottom">
          {noGoingBack ? (
            <div />
          ) : (
            <Button onClick={() => setStep(0)} icon={FiChevronLeft} transparent>
              {dictionary?.goBackToBasket}
            </Button>
          )}
          <Button htmlType="submit" icon={FiChevronRight} loading={submitting} alignIcon="right">
            {dictionary?.goToShipment}
          </Button>
        </div>
      </form>
    </StyledCheckoutForm>
  );
};
const StyledCheckoutForm = styled.div`
  .side-by-side {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    > div {
      margin: 0;
    }
  }

  .form-item {
    margin: 1rem 0;

    .error {
      color: red;
      margin: 0.5rem 0;
    }
  }
  .newsletter .checkbox label {
    align-items: flex-start;
  }
`;

export default CheckoutForm;
