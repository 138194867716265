import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { FiX } from 'react-icons/fi';
import { ContextProps, GlobalContext } from '../../context/global-context';
import { mqFrom, shadows } from '../../styles/styles';
import Basket from './basket';
import CheckoutForm from './checkout-form';
import ShippingForm from './shipping';
import Button from 'components/formitems/button';
import CheckoutSteps from './checkout-steps';

type StyledProps = {
  show: boolean;
};

const SidebarCheckout: React.FC = () => {
  const [step, setStep] = useState(0);
  const { popupState, setPopupState } = useContext<ContextProps>(GlobalContext);
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, (e) => {
    if (popupState === 'checkout' || popupState === 'product-reservation-and-checkout') {
      if ((e.target as HTMLElement) && (e.target as Element).closest('#modal-portal')) {
        console.log('Dont close on click inside modal');
      } else {
        setPopupState('none');
      }
    }
  });

  useEffect(() => {
    if (popupState === 'checkout' || popupState === 'product-reservation-and-checkout') {
      setStep(0);
    }
  }, [popupState]);

  const setStepAndScrollToTop = (step: number): void => {
    setStep(step);
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  return (
    <StyledSidebarCheckout show={popupState === 'checkout' || popupState === 'product-reservation-and-checkout'}>
      <div className="sidebar-checkout-background" />
      <div ref={ref} className="sidebar-checkout">
        <div className="top-bar">
          <Button onClick={() => setPopupState('none')} icon={FiX} transparent />
        </div>
        {step > 0 && <Basket step={step} setStep={setStepAndScrollToTop} compact collapsed hideBottom />}

        <CheckoutSteps step={step} setStep={setStepAndScrollToTop} />
        <div className="checkout-flow">
          {step === 0 && (
            <div>
              <Basket setStep={setStepAndScrollToTop} />
            </div>
          )}

          {step === 1 && (
            <div className="side-padding">
              <CheckoutForm setStep={setStepAndScrollToTop} />
            </div>
          )}
          {step === 2 && (
            <div className="side-padding">
              <ShippingForm setStep={setStepAndScrollToTop} />
            </div>
          )}
        </div>
      </div>
    </StyledSidebarCheckout>
  );
};

// const StyledSidebarCheckoutBackground = styled.div<{ show: boolean }>`
//   position: fixed;
//   top: ${(props) => (props.show ? 0 : '100%')};
//   bottom: 0;
//   left: 0;
//   right: 0;
//   opacity: ${(props) => (props.show ? 1 : 0)};
//   background: rgba(255, 255, 255, 0.5);
//   transition: opacity 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
// `;

const StyledSidebarCheckout = styled.div<StyledProps>`
  .sidebar-checkout-background {
    position: fixed;
    top: ${(props) => (props.show ? 0 : '100%')};
    bottom: 0;
    left: 0;
    right: 0;
    opacity: ${(props) => (props.show ? 1 : 0)};
    background: rgba(255, 255, 255, 0.5);
    transition: opacity 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .sidebar-checkout {
    position: fixed;
    top: 0;
    right: ${(props) => (props.show ? 0 : '-100%')};
    bottom: 0;
    background: var(--popup-background);
    width: 100%;
    max-width: 600px;
    z-index: 100;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: var(--popup-foreground);

    .compact-wrapper {
      overflow-y: hidden;
      overflow-x: visible;
    }

    .summery {
      display: flex;
    }

    .side-padding {
      padding-left: var(--side-padding);
      padding-right: var(--side-padding);
    }

    ${shadows.right} overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;

    .top-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1rem;

      > button {
        margin-left: auto;
      }
    }

    .checkout-flow {
    }

    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      margin: 2rem 0;

      > button {
        width: 100%;
        margin: 0.5rem 0;
      }

      ${mqFrom.desktop} {
        flex-direction: row;
        justify-content: space-between;
        > button {
          width: auto;
        }
      }
    }

    .shipping-form {
      .bottom {
        position: fixed;
        right: ${(props) => (props.show ? 0 : '-100%')};
        bottom: 0;
        background: var(--popup-background);
        width: 100%;
        max-width: 600px;
        z-index: 100;
        transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
        margin: 0 !important;
        padding: 1rem var(--side-padding);
        ${shadows.top};
      }
    }
  }
`;

export default SidebarCheckout;
