/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from '../../context/global-context';
import Button from '../formitems/button';

import Modal from 'components/core/modal';
import { FiX, FiCheck } from 'react-icons/fi';
import Loader from 'components/core/load-spinner';
import { useOrderStore } from 'data/orders';
import { CheckReservationSuccessData } from 'r2d2';
import Countdown from './countdown';
import { toast } from 'react-toastify';

type ReservationModalProps = {
  reservationId: string;
  exactSku: string;
  onClose: () => void;
};

const ReservationModal: React.FC<ReservationModalProps> = ({ reservationId, exactSku, onClose }) => {
  const { dictionary, setPopupState, popupState } = useContext<ContextProps>(GlobalContext);
  const router = useRouter();
  const checkReservation = useOrderStore((state) => state.checkReservation);
  const [reservationData, setReservationData] = useState<CheckReservationSuccessData | null>(null);

  // const currency = useOrderStore((state) => state?.order?.basket?.currency);
  // const { asPath, locale } = router;
  // const addToBasket = useOrderStore((state) => state.addToBasket);
  // const baseUrl = `https://${shop!.domain}`;
  // const variantSku = router.query?.variantSku as string;

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const checkRequest = await checkReservation(
        { reservationId, exactSku },
        { locale: router.locale!, path: router.asPath },
      );
      console.log('reservationId', reservationId, checkRequest);
      if (checkRequest && checkRequest.success) {
        const status = checkRequest.data?.reservation?.status;
        setReservationData(checkRequest.data);

        if (status === 'PENDING') {
          // Fetch data again
          setTimeout(getData, 2000);
        }
      }
      if (checkRequest && checkRequest.success === false) {
        toast(
          // @ts-ignore - Den siger at data er never
          checkRequest.data?.message ||
            // @ts-ignore - Den siger at data er never
            checkRequest.message ||
            'Unkown reservation error',
          {
            type: 'error',
          },
        );
      }
    };
    if (reservationId) setTimeout(getData, 500);
  }, [reservationId]);

  const handleClose = (): void => {
    onClose();
    setPopupState(popupState === 'product-reservation-and-checkout' ? 'checkout' : 'none');
  };

  return (
    <Modal
      visible={popupState === 'product-reservation' || popupState === 'product-reservation-and-checkout'}
      close={handleClose}
    >
      <StyledReservationModal className={`reservation-section`}>
        {(!reservationData || reservationData.reservation.status === 'PENDING') && (
          <div className="modal-content">
            <h3>{dictionary.reservationQueuedHead}</h3>
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{
                __html: dictionary.reservationQueuedBody,
              }}
            />
            <Loader />
          </div>
        )}
        {reservationData?.reservation.status === 'RESERVED' && (
          <div className="modal-content">
            <h3>{dictionary.reservationApprovedHead}</h3>
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{
                __html: dictionary.reservationApprovedBody,
              }}
            />
            <div className="center-countdown">
              <Countdown
                label={dictionary?.timeBeforeReservationExpires}
                millisecondsLeft={reservationData.countdown}
              />
            </div>

            <div className="confirm-modal-buttons">
              <Button size="small" icon={FiX} onClick={handleClose} transparent>
                {dictionary.keepShopping}
              </Button>
              <Button
                size="small"
                icon={FiCheck}
                onClick={() => {
                  setPopupState('checkout');
                  // router.push('/checkout');
                }}
              >
                {dictionary.goToCheckout}
              </Button>
            </div>
          </div>
        )}

        {reservationData?.reservation.status === 'DECLINED' && (
          <div className="modal-content">
            <h3>{dictionary.reservationDeclinedHead}</h3>
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{
                __html: dictionary.reservationDeclinedBody,
              }}
            />
            <div className="confirm-modal-buttons">
              <Button size="small" icon={FiX} onClick={handleClose} transparent>
                {dictionary.okayClose}
              </Button>
            </div>
          </div>
        )}

        {reservationData?.reservation.status === 'ERROR' && (
          <div className="modal-content">
            <h3>Error</h3>
            <div
              className="modal-body"
              dangerouslySetInnerHTML={{
                __html: reservationData.reservation.error || 'Unknown error',
              }}
            />
            <div className="confirm-modal-buttons">
              <Button size="small" icon={FiX} onClick={handleClose} transparent>
                {dictionary.okayClose}
              </Button>
            </div>
          </div>
        )}
      </StyledReservationModal>
    </Modal>
  );
};
const StyledReservationModal = styled.div`
  .modal-body {
    margin: 1rem 0;
  }

  .center-countdown {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
`;
export default ReservationModal;
