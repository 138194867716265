/* eslint-disable @typescript-eslint/no-empty-function */
import { Shop, Dictionary, Theme, SimplePage, CurrencyKeys } from 'r2d2';
import { createContext } from 'react';

const emptyDict = {
  notice: '',
  backToShop: '',
  email: '',
  firstName: '',
  lastName: '',
  address: '',
  address2: '',
  zipcode: '',
  city: '',
  country: '',
  phone: '',
  message: '',
  conditions: '',
  acceptConditions: '',
  acceptNewsletter: '',
  goToCheckout: '',
  goToPayment: '',
  goToShipment: '',
  goBackToCheckout: '',
  goBackToBasket: '',
  goBack: '',
  yourInformation: '',
  confirmOkay: '',
  confirmCancel: '',
  shipping: '',
  shippingOrderHeadline: '',
  shippingOrderBody: '',
  shippingRegularHeadline: '',
  shippingChosenDroppoint: '',
  shippingChooseDroppoint: '',
  noShippingMethodsHeader: '',
  noShippingMethodsBody: '',
  noItemsInBasket: '',
  basket: '',
  subtotal: '',
  vat: '',
  shippingFee: '',
  total: '',
  quantity: '',
  discount: '',
  showVoucherInput: '',
  showMessageInput: '',
  voucher: '',
  showOrderSummery: '',
  hideOrderSummery: '',
  purchaseCompleteLoadingTitle: '',
  receiptHead: '',
  receiptBody: '',
  receiptErrorBody: '',
  reciever: '',
  orderId: '',
  yourOrder: '',
  aboutArtistHeader: '',
  productDescription: '',
  addToBasket: '',
  addPeorderToBasket: '',
  relatedProducts: '',
  customTextVariantLabel: '',
  customTextVariantPlaceholder: '',
  customTextVariantErrorMinLength: '',
  customTextVariantErrorMaxLength: '',
  customTextVariantErrorSpecialChars: '',
  customTextVariantErrorNumbers: '',
  customTextVariantErrorLetters: '',
  customTextVariantErrorRequired: '',
  reservationQueuedHead: '',
  reservationQueuedBody: '',
  reservationApprovedHead: '',
  reservationApprovedBody: '',
  reservationDeclinedHead: '',
  reservationDeclinedBody: '',
  keepShopping: '',
  okayClose: '',
  timeBeforeReservationExpires: '',
  soldOut: '',
  soldOutBody: '',
  pleaseTypeInEmail: '',
  newsletter: '',
  signup: '',
  signedUpHeader: '',
  signedUpBody: '',
  newsletterBarHead: '',
  newsletterBarBody: '',
  pageNotFoundHead: '',
  pageNotFoundBody: '',
  pageNotSearchResults: '',
  notActivePageHead: '',
  notActivePageBody: '',
  moreInfo: '',
  day: '',
  days: '',
  hour: '',
  hours: '',
  minute: '',
  minutes: '',
  second: '',
  seconds: '',
  leftToPurchase: '',
  shippingStarts: '',
  finiteInfoHeader: '',
  finiteInfoBody: '',
  finiteCheckoutMessage: '',
  finiteBasketItem: '',
  loadingRegularPage: '',
  loadingProductPage: '',
  queueLoadingTitle: '',
  search: '',
  searchresults: '',
  searchProducts: '',
  queryDidntMatch: '',
  searchFound: '',
  searchFoundProducts: '',
  validationRequired: '',
  validationInvalidEmail: '',
  validationConditions: '',
  validationSelectCountry: '',
  validationPassword: '',
  passwordPlaceholder: '',
  passwordSubmit: '',
  cookieConsentReject: '',
  cookieConsentAccept: '',
  cookieConsentMessage: '',
  cookieConsentReadMoreLink: '',
  cookieConsentReadMore: '',
  cookieConsentAcceptAll: '',
  cookieConsentRejectAll: '',
  cookieConsentManageCookies: '',
  cookieConsentPopupHeader: '',
  cookieConsentPopupBody: '',
  cookieConsentRequiredHeader: '',
  cookieConsentRequiredBody: '',
  cookieConsentAnalyticsHeader: '',
  cookieConsentAnalyticsBody: '',
  cookieConsentAdvertisingHeader: '',
  cookieConsentAdvertisingBody: '',
  cookieConsentResetAll: '',
  cookieConsentSaveChanges: '',
  cookieConsentStateFull: '',
  cookieConsentStateAnalytics: '',
  cookieConsentStateAdvertising: '',
  cookieConsentStateRejected: '',
};

export type PopupStates =
  | 'sidebar'
  | 'checkout'
  | 'currency'
  | 'language'
  | 'alert'
  | 'product-reservation'
  | 'product-reservation-and-checkout'
  | 'none';

export type TrackingStates = 'none' | 'ready' | 'no-tracking';

export interface ContextProps {
  shop: Shop | null;
  dictionary: Dictionary;
  theme?: Theme;
  device: string;
  popupState: PopupStates;
  setPopupState: React.Dispatch<React.SetStateAction<PopupStates>>;
  pages: SimplePage[];
  trackingState: TrackingStates;
  setTrackingState: React.Dispatch<React.SetStateAction<TrackingStates>>;
  currentCurrency: CurrencyKeys;
  setCurrentCurrency: React.Dispatch<React.SetStateAction<CurrencyKeys>>;
  // currentCurrencyRate: number;
  // setCurrentCurrencyRate: React.Dispatch<React.SetStateAction<number>>;
}

export const GlobalContext = createContext<ContextProps>({
  shop: null,
  popupState: 'none',
  // currentCurrencyRate: 1,
  dictionary: emptyDict,
  device: '',
  setPopupState: () => {},
  pages: [],
  trackingState: 'none',
  setTrackingState: () => {},
  currentCurrency: 'DKK',
  setCurrentCurrency: () => {},
  // setCurrentCurrencyRate: () => {},
});
