import { FC } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import styled from 'styled-components';

export type CheckoutStepsProps = {
  setStep: (step: number) => void;
  step: number;
};

export const CheckoutSteps: FC<CheckoutStepsProps> = ({ setStep, step }) => {
  if (step === 0) {
    return null;
  }
  return (
    <StyledCheckoutSteps className="checkout-steps">
      <ol>
        <li>
          <button onClick={() => setStep(0)}>Indkøbskurv</button>
        </li>
        <li aria-hidden="true" className="seperator">
          <FiChevronRight />
        </li>
        <li>
          {step > 1 ? (
            <button onClick={() => setStep(1)}>Oplysninger</button>
          ) : (
            <span className={step === 1 ? 'current' : ''}>Oplysninger</span>
          )}
        </li>
        <li aria-hidden="true" className="seperator">
          <FiChevronRight />
        </li>
        <li>
          {step > 2 ? (
            <button onClick={() => setStep(1)}>Levering</button>
          ) : (
            <span className={step === 2 ? 'current' : ''}>Levering</span>
          )}
        </li>
        <li aria-hidden="true" className="seperator">
          <FiChevronRight />
        </li>
        <li>
          <span>Betaling</span>
        </li>
      </ol>
    </StyledCheckoutSteps>
  );
};

export const StyledCheckoutSteps = styled.div`
  padding: 0.5rem var(--side-padding);
  ol {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
      &.seperator {
        padding: 0 0.5rem;
        color: var(--base-dimmed-foreground);
        position: relative;
        top: 0.05rem;
      }

      font-size: 0.8rem;

      button {
        color: var(--secondary-background);
      }

      span {
        color: var(--base-dimmed-foreground);

        &.current {
          color: var(--base-foreground);
        }
      }
    }
  }
`;

export default CheckoutSteps;
