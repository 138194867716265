import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from 'context/global-context';
import { mqFrom } from 'styles/styles';
import ShoppingBagIcon from 'icons/shoppingbag';
import { useOrderStore } from 'data/orders';
import Countdown from 'components/product/countdown';
import { useRouter } from 'next/router';

const CartButton: FC = () => {
  const { setPopupState, dictionary } = useContext<ContextProps>(GlobalContext);
  const itemsCount: number = useOrderStore((state) => state.order?.basket?.totalItems || 0);
  const countdown: number = useOrderStore((state) => state.order?.basket?.priorityCountdownDate ?? 0);
  const clearReservations = useOrderStore((state) => state.clearReservations);
  const updateCustomerBasket = useOrderStore((state) => state.getCustomerOrder);
  const { locale, asPath: path } = useRouter();

  return (
    <StyledCartButton
      type="button"
      className="cart-button"
      onClick={() => {
        updateCustomerBasket({ locale: locale!, path });
        setPopupState((val) => (val === 'checkout' ? 'none' : 'checkout'));
      }}
    >
      <div className="inner">
        <ShoppingBagIcon size={24} />
        <div className="badge small">{itemsCount}</div>
      </div>
      {countdown > 0 && (
        <Countdown
          label={dictionary?.timeBeforeReservationExpires}
          millisecondsLeft={countdown}
          onCompleted={() => {
            clearReservations({ locale: locale!, path });
          }}
        />
      )}
    </StyledCartButton>
  );
};
const StyledCartButton = styled.button`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .countdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-background);
    color: var(--primary-foreground);
    z-index: 1;

    ${mqFrom.tablet} {
      padding: 0 2rem;
      width: auto;
      right: 0;
      left: auto;

      /* top: var(--navigation-height); */
    }
  }

  ${mqFrom.tablet} {
    padding: 0 2rem;
  }

  .inner {
    position: relative;
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    background: var(--primary-background);
    color: var(--primary-foreground);
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default CartButton;
