/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';
import classNames from 'classnames';

export type CheckboxProps = {
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  errorMessage?: string;
};
export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  onChange = () => {},
  label,
  checked,
  errorMessage,
  ...rest
}) => (
  <>
    <StyledCheckbox className={classNames({ 'has-error': !!errorMessage, checkbox: true })}>
      <input type="checkbox" id={name} name={name} onChange={onChange} checked={checked} {...rest} />
      <label htmlFor={name}>
        <div className="box" />
        <div className="label" dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </StyledCheckbox>
    {errorMessage && <div className="error-message">{errorMessage}</div>}
  </>
);
const StyledCheckbox = styled.div`
  position: relative;
  width: 100%;

  input {
    position: absolute;
  }

  input[type='checkbox']:checked + label .box {
    background: var(--secondary-background);

    &:after {
      opacity: 1;
      border-color: var(--secondary-foreground);
    }
  }

  label {
    margin: 0.6rem 0;
    width: 100%;
    display: flex;
    opacity: 1;
    order: 1;
    transform: translate(0, 0);
    transition: all 0.2s ease-in-out;
    /* top: 0;
    left: 0;
    position: absolute; */
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .box {
      position: relative;
      cursor: pointer;
      width: 25px;
      height: 25px;
      top: 0;
      left: 0;
      background: var(--input-background);
      border: 1px solid var(--input-border);
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

      &:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 12px;
        height: 8px;
        background: transparent;
        top: 6px;
        left: 7px;
        border: 3px solid var(--secondary-background);
        border-top: none;
        border-right: none;
        transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(-45deg);
      }
    }

    .label {
      width: calc(100% - 40px);
      margin-left: 10px;
      /* margin-top: 6px; */
      font-size: 0.9rem;
      padding-right: 40px;

      a {
        text-decoration: underline;
      }
    }
  }

  &.has-error {
    .box {
      border-color: var(--error-foreground);
    }
    .label {
      color: var(--error-foreground);
    }
  }
`;

export default Checkbox;
