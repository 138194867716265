/* eslint-disable @next/next/no-img-element */
import { FC, useEffect, useRef, useState } from 'react';

export type TrimmedDataImageProps = {
  base64?: string;
  className?: string;
  alt?: string;
};

type Bounds = {
  top: number | null;
  left: number | null;
  right: number | null;
  bottom: number | null;
  width: number | null;
  height: number | null;
};

export const TrimmedDataImage: FC<TrimmedDataImageProps> = ({ base64 = '', className = '', alt = '' }) => {
  const [modifiedBase64, setModifiedBase64] = useState<string>('');
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const getTrimmedData = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): Bounds => {
    const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const l = pixels.data.length;
    const bound: Bounds = {
      top: null,
      left: null,
      right: null,
      bottom: null,
      width: null,
      height: null,
    };
    let x, y, i;

    for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
        x = (i / 4) % canvas.width;
        y = ~~(i / 4 / canvas.width);

        if (bound.top === null) {
          bound.top = y;
        }

        if (bound.left === null) {
          bound.left = x;
        } else if (x < bound.left) {
          bound.left = x;
        }

        if (bound.right === null) {
          bound.right = x;
        } else if (bound.right < x) {
          bound.right = x;
        }

        if (bound.bottom === null) {
          bound.bottom = y;
        } else if (bound.bottom < y) {
          bound.bottom = y;
        }
      }
    }

    const trimHeight = (bound.bottom ?? 0) - (bound.top ?? 0) + 1;
    const trimWidth = (bound.right ?? 0) - (bound.left ?? 0) + 1;
    // trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

    bound.width = trimWidth;
    bound.height = trimHeight;

    return bound;
  };

  const trimImage = (): void => {
    const canvas: HTMLCanvasElement = canvasRef.current as HTMLCanvasElement;
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    const image = new Image();
    image.onload = function () {
      context.drawImage(image, 0, 0);
      const bounds = getTrimmedData(canvas, context);
      const newCanvas = document.createElement('canvas');

      newCanvas.width = bounds.width ?? 0;
      newCanvas.height = bounds.height ?? 0;

      const newContext = newCanvas.getContext('2d') as CanvasRenderingContext2D;

      // clip just the non-transparent pixels and draw them to the in-memory canvas
      newContext.drawImage(
        canvas,
        bounds.left ?? 0,
        bounds.top ?? 0,
        bounds.width ?? 0,
        bounds.height ?? 0,
        0,
        0,
        bounds.width ?? 0,
        bounds.height ?? 0,
      );

      const dataURL = newCanvas.toDataURL();
      setModifiedBase64(dataURL);
    };
    image.src = base64;
  };

  useEffect(() => {
    if (base64 && canvasRef && !modifiedBase64) {
      trimImage();
    }
  }, [base64, canvasRef]); // eslint-disable-line

  if (!base64) {
    return null;
  }

  if (modifiedBase64) {
    return <img src={modifiedBase64} alt={alt} className={`trimmed ${className}`} />;
  }

  return (
    <div style={{ display: 'none' }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default TrimmedDataImage;
