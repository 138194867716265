/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import Layout from './master-layout';
import styled from 'styled-components';
import React, { useContext } from 'react';
import { ContextProps, GlobalContext } from 'context/global-context';

export type NotActivePageProps = {}; // eslint-disable-line

export const NotActivePage: React.FC<NotActivePageProps> = () => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);

  return (
    <Layout cssClassName="not-found" empty>
      <Head>
        <title>{dictionary?.notActivePageHead}</title>
      </Head>
      <StyledNotActivePage>
        <div className="inner-headers">
          <img src="/beatdown-logo.svg" alt="Beatdown Merchandise" />
          <h1 className="display">{dictionary?.notActivePageHead}</h1>
          <p>{dictionary?.notActivePageBody}</p>
        </div>
      </StyledNotActivePage>
    </Layout>
  );
};
const StyledNotActivePage = styled.main`
  .inner-headers {
    min-height: calc(50vh - var(--navigation-height));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 800px;
    margin: auto;
    padding: 2rem var(--side-padding);

    h1 {
      margin-top: 0;
    }

    img {
      max-width: 200px;
      margin-bottom: 2rem;
    }
  }
`;

export default NotActivePage;
