import { FC } from 'react';

export type ShoppingBagIconProps = {
  size?: number;
  color?: string;
  className?: string;
};

export const ShoppingBagIcon: FC<ShoppingBagIconProps> = ({ size = 20, color = 'white', className = '' }) => (
  <svg width={size} height={size} viewBox="0 0 18 20" fill="none" className={className}>
    <path
      d="M13.5 6.22848V4.38185C13.5 3.80641 13.3836 3.23662 13.1575 2.70499C12.9313 2.17336 12.5998 1.69031 12.182 1.28341C11.7641 0.876521 11.268 0.553756 10.7221 0.333548C10.1761 0.11334 9.59095 0 9 0C8.40905 0 7.82389 0.11334 7.27792 0.333548C6.73196 0.553756 6.23588 0.876521 5.81802 1.28341C5.40016 1.69031 5.06869 2.17336 4.84254 2.70499C4.6164 3.23662 4.5 3.80641 4.5 4.38185V8.76369C4.5 8.92971 4.56773 9.08893 4.68829 9.20633C4.80885 9.32372 4.97236 9.38967 5.14286 9.38967C5.31335 9.38967 5.47687 9.32372 5.59743 9.20633C5.71798 9.08893 5.78571 8.92971 5.78571 8.76369V7.48044H10.9286V6.22848H5.78571V4.38185C5.78571 3.55175 6.12436 2.75565 6.72716 2.16868C7.32995 1.58171 8.14752 1.25196 9 1.25196C9.85248 1.25196 10.67 1.58171 11.2728 2.16868C11.8756 2.75565 12.2143 3.55175 12.2143 4.38185V8.73239C12.2143 8.89841 12.282 9.05763 12.4026 9.17503C12.5231 9.29242 12.6866 9.35837 12.8571 9.35837C13.0276 9.35837 13.1912 9.29242 13.3117 9.17503C13.4323 9.05763 13.5 8.89841 13.5 8.73239V7.48044H16.7143V18.748H1.28571V7.48044H3.21429V6.22848H0V18.8044C0 19.1215 0.129363 19.4256 0.359631 19.6498C0.589899 19.874 0.902209 20 1.22786 20H16.7721C17.0978 20 17.4101 19.874 17.6404 19.6498C17.8706 19.4256 18 19.1215 18 18.8044V6.22848H13.5Z"
      fill={color}
    />
  </svg>
);

export default ShoppingBagIcon;
