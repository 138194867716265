import { ShopResponse, ApiResponse, GetShopSuccessData } from 'r2d2';

export const getShopSettings = async (locale: string): Promise<ShopResponse> => {
  const res = await fetch(`/api/content/get-settings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ locale, includeNavigation: false }),
  });
  const json = (await res.json()) as ApiResponse<GetShopSuccessData, null>;

  return json.data.shop;
};
