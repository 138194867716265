import React, { useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

export type InputProps = {
  value?: string;
  size?: 'default' | 'large' | 'small';
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  className?: string;
  name?: string;
  autoComplete?: string;
  errorMessage?: string;
};

export const Input: React.FC<InputProps> = ({
  placeholder,
  rows,
  onFocus,
  onBlur,
  name,
  size = 'default',
  autoComplete,
  errorMessage,
  ...props
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <StyledInput
        className={classNames(
          `input`,
          `size-${size}`,
          { 'is-focused': focus },
          { 'has-placeholder': placeholder },
          { 'has-value': props.value },
          { 'has-error': errorMessage },
          { disabled: props.disabled },
        )}
      >
        {rows && rows > 1 ? (
          <textarea
            {...props}
            autoComplete={autoComplete}
            name={name}
            rows={rows}
            onFocus={(e) => {
              setFocus(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={(e) => {
              setFocus(false);
              if (onBlur) {
                onBlur(e);
              }
            }}
          />
        ) : (
          <input
            {...props}
            name={name}
            autoComplete={autoComplete}
            onFocus={(e) => {
              setFocus(true);
              if (onFocus) {
                onFocus(e);
              }
            }}
            onBlur={(e) => {
              setFocus(false);
              if (onBlur) {
                onBlur(e);
              }
            }}
          />
        )}
        {placeholder && (
          <label className="placeholder" htmlFor={name}>
            {placeholder}
          </label>
        )}
      </StyledInput>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};

const StyledInput = styled.div``;
export default Input;
