/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-use-before-define */
// import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import UAParser from 'ua-parser-js';
import Cookie from 'js-cookie';
import { BASKET_TOKEN_KEY, IS_PRODUCTION } from './constants';
import { fbPageView } from './fbpixel';
import { Receipt, Shop } from 'r2d2';
// import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';

export const initBDTracking = (): void => {
  if (process.browser && window && !window._bdt) {
    // if (IS_PRODUCTION) {
    // if (!document.querySelector('#tracker')) {
    //   const tracker = document.createElement('div');
    //   tracker.id = 'tracker';
    //   document.body.appendChild(tracker);
    //   window._bdt = tracker;
    //   const ua = new UAParser(navigator.userAgent);
    //   console.log('UserAgent', ua.getResult());
    //   logBDEvent('init', 'useragent', ua.getResult() as Record<string, any>);
    // }
    // }
    // else {
    //   window._bdt = 'DEV_MODE';
    //   const ua = new UAParser(navigator.userAgent);
    //   logBDEvent('init', 'useragent', ua.getResult());
    // }
  }
};

export const logBDEvent = (event: string, action = '', info: Record<string, unknown> = {}): void => {
  if (process.browser && window && window._bdt) {
    // console.log('logBDEvent', event, action, window._bdt);

    // if (!window._bdt) {
    //   initBDTracking();
    // }
    if (IS_PRODUCTION) {
      //   const token = Cookie.get(BASKET_TOKEN_KEY);
      //   const img = document.createElement('img');
      //   const tracker = window._bdt;
      //   img.setAttribute(
      //     'src',
      //     `${process.env.NEXT_PUBLIC_DECKARD_URL}?token=${token}&shop=${
      //       process.env.NEXT_PUBLIC_SHOP_SLUG
      //     }&event=${event}&action=${encodeURIComponent(action)}&info=${encodeURIComponent(
      //       JSON.stringify(info),
      //     )}&url=${encodeURIComponent(window.location.href)}`,
      //   );
      //   if (tracker) {
      //     tracker.appendChild(img);
      //     setTimeout(() => {
      //       tracker.removeChild(img);
      //     }, 200);
      //   } else {
      //     document.body.append(img);
      //     setTimeout(() => {
      //       document.body.removeChild(img);
      //     }, 200);
      //   }
      // } else {
      //   console.log('[BD-TRACK]', event, action, info);
      // }
    }
  }
};
export const logPageView = (): void => {
  if (process.browser && IS_PRODUCTION) {
    // const url = window.location.href;
    // if (window?.ga) {
    //   ReactGA.pageview(url);
    // }
    fbPageView();
    logBDEvent('pageview', 'route-change');
  }
};

export const revokeGA = (): void => {
  // Remove the default tracker.
  if (window.ga) {
    window.ga('remove');
    delete window.ga;
  }

  const domainArr = document.domain.split('.');
  const rootDomain = `.${domainArr[domainArr.length - 2]}.${domainArr[domainArr.length - 1]}`;
  // Root domainfordi at teknisk set skal man fjerne cookie på .domain.dk

  // Remove the default cookies
  // _ga is used to distinguish users.
  Cookie.remove('_ga', { path: '/', domain: document.domain });
  Cookie.remove('_ga', { path: '' });
  Cookie.remove('_ga', { path: '', domain: rootDomain });
  // _gid is used to distinguish users.
  Cookie.remove('_gid', { path: '/', domain: document.domain });
  Cookie.remove('_gid', { path: '' });
  Cookie.remove('_gid', { path: '', domain: rootDomain });
  // _gat is used to throttle request rate.
  Cookie.remove('_gat', { path: '/', domain: document.domain });
  Cookie.remove('_gat', { path: '' });
  Cookie.remove('_gat', { path: '', domain: rootDomain });
};

export const logGAEvent = (_category = '', _action = ''): void => {
  // if (category && action && process.browser && window?.ga && IS_PRODUCTION) {
  //   ReactGA.event({ category, action });
  // }
};
export const logGAEcommerce = (organization: Shop, receipt: Receipt): void => {
  if (process.browser && window?.ga && IS_PRODUCTION) {
    // const { basket, orderId } = receipt;
    // const { currency, items, totalPriceIncludeShipping, shippingPrice, vat } =
    //   basket;
    // ReactGA.plugin.require('ecommerce');
    // ReactGA.plugin.execute('ecommerce', 'addTransaction', {
    //   id: orderId,
    //   affiliation: organization.name,
    //   revenue: totalPriceIncludeShipping,
    //   shipping: shippingPrice,
    //   tax: vat,
    //   currency,
    // });
    // items.forEach((item) => {
    //   ReactGA.plugin.execute('ecommerce', 'addItem', {
    //     id: orderId,
    //     name: item.name,
    //     sku: item.exactSku,
    //     // category: 'Party Toys', // Category or variation.
    //     price: item.price.toFixed(2),
    //     quantity: item.amount.toString(),
    //     currency,
    //   });
    // });
    // ReactGA.plugin.execute('ecommerce', 'send', {});
  } else if (process.browser) {
    console.log('// GA NOT INITIALIZED', organization, receipt);
  }
};

export const logSentryEvent = (message: string, data?: Record<string, unknown>): void => {
  if (process.browser && IS_PRODUCTION) {
    Sentry.addBreadcrumb({
      message,
      data,
    });
  }
};

export const logCrispEvent = (event: string, data: Record<string, unknown>, color: string): void => {
  if (window.$crisp) {
    window.$crisp.push(['set', 'session:event', [[[event, data, color || 'red']]]]);
  }
};

export const logCrispData = (key: string, value: string): void => {
  if (window.$crisp) {
    if (['email', 'nickname', 'phone'].includes(key)) {
      if (key === 'email') {
        Sentry.setUser({ emai: value });
      }
      window.$crisp.push(['set', `user:${key}`, [value]]);
    } else {
      window.$crisp.push(['set', 'session:data', [key, value]]);
    }
  }
};
