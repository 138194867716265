import React, { useEffect, useContext, useState, useMemo } from 'react';
import styled from 'styled-components';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useRouter } from 'next/router';
import { ContextProps, GlobalContext } from '../../context/global-context';
import Button from '../formitems/button';
import Loader from '../core/load-spinner';
import { getShippingMethods, useOrderStore } from '../../data/orders';
import ShippingItem from './shipping-item';
import { toast } from 'react-toastify';
import { ShippingMethod } from 'r2d2';

type ShippingFormProps = {
  setStep: (step: number) => void;
};

type ContextType = {
  locale: string;
  path: string;
};

const ShippingForm: React.FC<ShippingFormProps> = ({ setStep }) => {
  const { dictionary } = useContext<ContextProps>(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[] | null>(null);

  // const order = useOrderStore((state) => state.order);
  const customerShipping = useOrderStore((state) => state.order?.shipping);
  const updateOrder = useOrderStore((state) => state.getCustomerOrder);

  const { locale, asPath: path, push } = useRouter();

  const createOrderAndGoToQueue = useOrderStore((state) => state.createOrderAndGoToQueue);

  const ctx: ContextType = useMemo(
    () => ({
      locale: locale!,
      path,
    }),
    [locale, path],
  );

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      const shippingMethodData = await getShippingMethods(ctx);

      if (shippingMethodData.success) {
        setShippingMethods(shippingMethodData.data.shippingMethods);
      }
      if (shippingMethodData.success === false) {
        setShippingMethods([]);
        toast(
          // @ts-ignore - Den siger at data er never
          shippingMethodData.data?.message ||
            // @ts-ignore - Den siger at data er never
            shippingMethodData.message ||
            'Unkown reservation error',
          {
            type: 'error',
          },
        );
      }

      setLoading(false);
    };

    getData();
  }, [setLoading, ctx]);

  if (!shippingMethods) {
    return null;
  }

  if (shippingMethods && !shippingMethods.length && !loading) {
    return (
      <div>
        <h3>{dictionary?.noShippingMethodsHeader}</h3>
        <p>{dictionary?.noShippingMethodsBody}</p>
      </div>
    );
  }

  const goToQueue = async (): Promise<void> => {
    const res = await createOrderAndGoToQueue(ctx);

    if (res.success) {
      push(`/queue?orderId=${(res.data as ResponseData)?.orderId}&token=${(res.data as ResponseData)?.token}`);
    } else {
      toast(res.data.message, { type: 'error' });

      if ((res.data as ResponseData)?.goto === 'basket') {
        updateOrder({ locale: locale!, path });
        setStep(0);
      }
      if ((res.data as ResponseData)?.goto === 'checkout') {
        updateOrder({ locale: locale!, path });
        setStep(1);
      }
    }
  };

  const { droppoint: selectedDroppoint, id: selectedShippingId, orderId: selectedOrderId } = customerShipping || {};
  // const selecteddroppointId = droppoint?.id;
  const mergeOrderOptions = shippingMethods.filter((item) => item.id === -1 && item.orderId);
  const droppointOptions = shippingMethods?.filter((item) => item.requireDropPoint && item.droppoints?.length);
  const regularOptions = shippingMethods?.filter(
    (item) => !(item.requireDropPoint && item.droppoints?.length) && !(item.id === -1 && item.orderId),
  );

  return (
    <StyledShippingForm className="shipping-form">
      <h3>{dictionary?.shipping}</h3>
      {loading ? (
        <Loader title="Henter forsendelsesmetoder" />
      ) : (
        <>
          {!!mergeOrderOptions.length && <h4>{dictionary?.shippingOrderHeadline}</h4>}
          {!!mergeOrderOptions.length && <p>{dictionary?.shippingOrderBody}</p>}
          {!!mergeOrderOptions.length && (
            <div className="list-group shipping-methods">
              {mergeOrderOptions.map((item) => {
                const selected = item.id === selectedShippingId && item.orderId === selectedOrderId;
                return (
                  <ShippingItem
                    key={item.orderId}
                    selected={selected}
                    name={item.name}
                    logo={item.carrierLogo}
                    carrierName={item.carrierName}
                    description={item.description || ''}
                    price={item.priceFormatted}
                    shippingId={item.id}
                    orderId={item.orderId}
                    onLoading={(loading) => setSubmitting(loading)}
                  />
                );
              })}
            </div>
          )}
          {!!mergeOrderOptions.length && <h4>{dictionary?.shippingRegularHeadline}</h4>}
          <div className="list-group shipping-methods">
            {droppointOptions.map((item) => {
              const selected = item.id === selectedShippingId;

              return (
                <ShippingItem
                  key={item.id}
                  selected={selected}
                  name={item.name}
                  logo={item.carrierLogo}
                  carrierName={item.carrierName}
                  price={item.priceFormatted}
                  description={''}
                  shippingId={item.id}
                  selectedDroppointId={selectedShippingId === item.id ? selectedDroppoint?.id : null}
                  droppoints={item.droppoints}
                  onLoading={(loading) => setSubmitting(loading)}
                />
              );
            })}
            {regularOptions.map((item) => {
              const selected = item.id === selectedShippingId;
              return (
                <ShippingItem
                  key={item.id}
                  selected={selected}
                  name={item.name}
                  logo={item.carrierLogo}
                  carrierName={item.carrierName}
                  description={item.description || ''}
                  price={item.priceFormatted}
                  shippingId={item.id}
                  orderId={item.orderId}
                  onLoading={(loading) => setSubmitting(loading)}
                />
              );
            })}
          </div>
        </>
      )}

      <div className="bottom">
        <Button onClick={() => setStep(1)} icon={FiChevronLeft} alignIcon="left" transparent>
          {dictionary?.goBackToCheckout}
        </Button>
        <Button
          htmlType="submit"
          onClick={goToQueue}
          icon={FiChevronRight}
          alignIcon="right"
          loading={submitting}
          disabled={loading || submitting}
        >
          {dictionary?.goToPayment}
        </Button>
      </div>
    </StyledShippingForm>
  );
};

const StyledShippingForm = styled.div`
  padding-bottom: 8rem;
  .list-group {
    background: var(--input-background);
    border: 1px solid var(--input-border);
    color: var(--input-foreground);
    border-radius: var(--input-border-radius);
    margin-bottom: 3rem;

    .item {
      border-bottom: 1px solid var(--input-border);
      padding: 1rem;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
`;

export default ShippingForm;
