import { buildURL, SharedImgixAndSourceProps } from 'react-imgix';
import { BASKET_TOKEN_KEY, CURRENCIES, IMGIX_HOST } from './constants';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const logForDebug = (...logs: unknown[]): void => {
  // eslint-disable-line
  // console.log(...logs);
  // if (process.env.NODE_ENV === 'development') {
  // }
};

export const isExternalLink = (link: string): boolean => {
  const isExtLink =
    link.trim().toLowerCase().startsWith('https://') ||
    link.trim().toLowerCase().startsWith('http://') ||
    link.trim().toLowerCase().startsWith('mailto:') ||
    link.trim().toLowerCase().startsWith('tel:');
  return isExtLink;
};

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const buildImgixUrl = (
  src: string,
  imgixParams?: Record<string, unknown>,
  options?: SharedImgixAndSourceProps,
): string => {
  if (!src) {
    return '';
  }
  const imgixUrl = `${IMGIX_HOST}${src}`;
  const buildedUrl = buildURL(imgixUrl, imgixParams, options);
  // console.log('/// buildedUrl', buildedUrl);
  return buildedUrl.replace(/&amp;/g, '&');
};

export const getToken = (): string => {
  if (typeof window === 'undefined') {
    throw new Error('Getting token from server');
  }
  let token = Cookies.get(BASKET_TOKEN_KEY);
  if (!token) {
    token = `${process.env.NEXT_PUBLIC_SHOP_SLUG || 'bd'}_${uuid()}`;
    Cookies.set(BASKET_TOKEN_KEY, token, { expires: 30 });
  }
  return token;
};

export const round = (num: number): number => Math.round(num * 100) / 100;

export const getPriceFormatted = (price: number, rate: number, currency: string, fallback?: string): string => {
  if (!price || !rate || !currency) {
    return fallback || '';
  }

  const cur = CURRENCIES.find((c) => c.key === currency);
  const convertedPrice = round(price * rate);
  // console.log('getPriceFormatted', {
  //   price,
  //   rate,
  //   currency,
  //   fallback,
  //   convertedPrice,
  //   cur,
  // });
  return cur!.format(convertedPrice);
};

export const isEmail = (email: string): boolean => {
  const trimmedEmail = (String(email) || '').trim().toLowerCase();
  const emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  const isValid = emailRegExp.test(trimmedEmail);
  return isValid;
};
