import { BuySectionProduct, ApiResponse, GetSearchResultSuccessData, Locales } from 'r2d2';
import { getToken } from 'utils/helpers';

export const getSearchData = async (
  searchQuery: string,
  locale: Locales,
): Promise<ApiResponse<GetSearchResultSuccessData, null>> => {
  const searchResult = await fetch('/api/products/search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: getToken(),
      locale,
      query: searchQuery,
    }),
  });
  const data = (await searchResult.json()) as ApiResponse<GetSearchResultSuccessData, null>;

  return data;
};

export const getProductBuyData = async (
  slug: string,
  path: string,
  locale: Locales,
  selectedVariants?: Record<string, string>,
): Promise<BuySectionProduct> => {
  const productDataNew = await fetch('/api/checkout/product-info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      path,
      token: getToken(),
      locale,
      slug,
      selected: selectedVariants,
    }),
  });
  const data = await productDataNew.json();
  return data;
};
