import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/normalize.css';
import 'styles/reset.css';
// import { initSentry } from 'utils/sentry';
import { AppWrapper } from '../layout/app-wrapper';
import 'core-js/features/string/match-all';
import { ShopResponse, SimplePage } from 'r2d2';
import NotActivePage from 'layout/not-active';

// initSentry();

function GlobalApp({ Component, pageProps }: AppProps<{ shop: ShopResponse; pages: SimplePage[] }>): JSX.Element {
  // eslint-disable-line

  if (!pageProps.shop) {
    return <Component {...pageProps} />;
  }

  const shop: ShopResponse = pageProps.shop!;
  const pages: SimplePage[] = pageProps.pages!;

  const sameAs: string[] = ['facebook', 'instagram', 'twitter', 'youtube', 'spotify', 'itunes', 'soundcloud', 'twitch']
    //@ts-ignore
    .map((key: keyof Socials) => shop.socials?.[key] || '')
    .filter((link) => !!link);

  const baseUrl = `https://${shop.domain}`;
  // console.log('pageProps.shop', pageProps.shop.active);

  const isBeatdownPage = ['beatdown', 'finite', 'tilted'].includes(shop.slug);

  if (pageProps.shop.active === false) {
    return (
      <AppWrapper shop={shop} pages={[]}>
        <NotActivePage />
      </AppWrapper>
    );
  }

  return (
    <>
      <AppWrapper shop={shop} pages={pages}>
        <Component {...pageProps} />
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          className="toaster"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Corporation',
              name: shop.name,
              alternateName: 'Beatdown Merchandise',
              url: isBeatdownPage ? 'https://www.beatdown.io' : baseUrl,
              logo: shop.logo ? `http://beatdown.imgix.net${shop.logo.src}` : '',
              sameAs,
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'WebSite',
              name: shop.defaultTitle,
              url: baseUrl,
              potentialAction: {
                '@type': 'SearchAction',
                target: `${baseUrl}/search?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
              },
            }),
          }}
        />
      </AppWrapper>
    </>
  );
}

export default GlobalApp;
