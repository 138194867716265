/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';

export type SelectProps = {
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  value?: string;
  name?: string;
  defaultValue?: string;
  rows?: number;
  className?: string;
  autoComplete?: string;
  errorMessage?: string;
  disabled?: boolean;
  options: string[];
  size?: 'default' | 'large' | 'small';
};
export const Select: React.FC<SelectProps> = ({
  disabled,
  name,
  options = [],
  placeholder,
  autoComplete,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  size = 'default',
  className = '',
  errorMessage,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const ref = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (!props.value && options[0] && ref.current) {
      const event = new Event('change', { bubbles: true });
      ref.current.dispatchEvent(event);
    }
  }, [ref]); // eslint-disable-line

  return (
    <>
      <StyledSelect
        className={classNames(
          `select`,
          className,
          `size-${size}`,
          { 'is-focused': focus },
          { 'has-placeholder': placeholder },
          { 'has-value': props.value || options[0] },
          { 'has-error': errorMessage },
        )}
      >
        {placeholder && <label htmlFor={name}>{placeholder}</label>}
        <select
          ref={ref}
          size={1}
          name={name}
          autoComplete={autoComplete}
          {...props}
          onChange={onChange}
          onFocus={(e) => {
            setFocus(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            setFocus(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
          disabled={disabled}
        >
          {options.map((opt) => (
            <option key={opt} value={opt === '---' ? '' : opt} disabled={!opt || opt === '---'}>
              {opt}
            </option>
          ))}
        </select>
        <div className="caret">
          <FiChevronDown />
        </div>
      </StyledSelect>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};
const StyledSelect = styled.div`
  select {
    padding-right: calc(var(--input-size) * 0.8) !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .caret {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    pointer-events: none;
  }
`;

export default Select;
