/* eslint-disable @next/next/no-img-element */
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { FiChevronDown, FiChevronLeft, FiChevronRight, FiChevronUp, FiTrash2 } from 'react-icons/fi';
import { buildImgixUrl } from 'utils/helpers';
import { useOrderStore } from 'data/orders';
import { ContextProps, GlobalContext } from 'context/global-context';
import Viabill from '../product/viabill';
import Button from '../formitems/button';
import Input from '../formitems/input';
import QuantityPicker from 'components/formitems/quantity-picker';
import Checkbox from 'components/formitems/checkbox';
import { useSpring, animated } from 'react-spring';
import ShoppingBagIcon from 'icons/shoppingbag';
import { mqFrom } from 'styles/styles';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { Order } from 'r2d2';
import ReservationModal from 'components/product/reservation-modal';
import Countdown from 'components/product/countdown';
import { IS_PRODUCTION } from 'utils/constants';

type BasketProps = {
  setStep?: (step: number) => void;
  compact?: boolean;
  step?: number;
  showBackToShopButton?: boolean;
  collapsed?: boolean;
  hideBottom?: boolean;
};

type FormValues = {
  conditions: boolean;
};

const Basket: React.FC<BasketProps> = ({
  setStep = () => null,
  compact = false,
  showBackToShopButton = false,
  step = 0,
  collapsed,
  hideBottom,
}) => {
  const { dictionary, device, shop, setPopupState } = useContext<ContextProps>(GlobalContext);
  const router = useRouter();
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showVoucher, setShowVoucher] = useState(false);
  const isDesktop = ['desktop', 'wide'].includes(device);
  const [reservationIdAndSku, setReservationIdAndSku] = useState<{
    reservationId: string;
    exactSku: string;
  } | null>(null);

  const [expanded, setExpanded] = useState(!collapsed && isDesktop);

  const order: Order = useOrderStore((state) => state.order!);
  const setVoucher = useOrderStore((state) => state.setVoucher);
  const setCustomerInfo = useOrderStore((state) => state.setCustomerInfo);

  const customer = useOrderStore((state) => {
    if (!state.fetched) {
      return null;
    }
    return {
      conditions: state.order?.conditions || false,
    };
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm<FormValues>({
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const ref = useRef<HTMLDivElement>(null);

  const changeQuantityOnLine = useOrderStore((state) => state.changeQuantityOnLine);

  const [props, set] = useSpring(() => ({ height: compact ? 0 : 'auto' }));
  const ctx = { locale: router.locale!, path: router.asPath };

  useEffect(() => {
    if (ref.current && compact) {
      const h = ref.current.getBoundingClientRect().height;

      set({ height: expanded ? h : 0 });
    }
  }, [expanded, ref?.current]); // eslint-disable-line

  useEffect(() => {
    if (expanded && !compact) {
      setExpanded(false);
      // log
    }
  }, [step]); // eslint-disable-line

  if (!order?.basket?.items?.length) {
    return <StyledEmptyBasket>{dictionary?.noItemsInBasket}</StyledEmptyBasket>;
  }

  const { basket } = order;

  const changeQuantity = async (lineId: string, amount: number): Promise<void> => {
    const quantityData = await changeQuantityOnLine(
      {
        lineId,
        amount,
      },
      ctx,
    );

    if (quantityData.success && quantityData.data.type === 'PRIORITY' && quantityData.data.reservationId) {
      setReservationIdAndSku({
        reservationId: quantityData.data.reservationId,
        exactSku: quantityData.data.exactSku,
      });
      setPopupState('product-reservation-and-checkout');
    }
    if (!quantityData.success) {
      toast(quantityData.data.message, { type: 'error' });
    }
  };

  const onVoucherBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setVoucher({ code: e.target.value }, ctx);
  };

  const onSubmit = async (inputdata: Record<string, unknown>): Promise<void> => {
    setSubmitting(true);
    const res = await setCustomerInfo({ ...inputdata }, ctx);

    if (res.success) {
      setStep(1);
    } else {
      toast(res.data.message, { type: 'error' });
      if (res.data.errors?.length) {
        const errors = res.data.errors;
        errors.forEach((error) => {
          if (error.type === 'checkout') {
            setError(error.field as keyof FormValues, {
              type: 'manual',
              message: error.message,
            });
          } else {
            toast(error.message, { type: 'error' });
          }
        });
      } else if (res.data.goto === 'basket') {
        setStep(0);
      }

      setSubmitting(false);
    }
  };

  return (
    <StyledBasket
      className={classNames({
        'basket-wrapper': true,
        visual: hideBottom,
        compact,
        expanded,
      })}
    >
      {/* Reserved product modal */}
      {reservationIdAndSku && (
        <ReservationModal
          reservationId={reservationIdAndSku.reservationId}
          exactSku={reservationIdAndSku.exactSku}
          onClose={() => setReservationIdAndSku(null)}
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {!compact && (
          <div className="header-center">
            <h3 className="display">{dictionary?.basket}</h3>
          </div>
        )}
        {compact && (
          <button
            className={classNames({ summery: true, expanded })}
            type="button"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <div>
              <ShoppingBagIcon color={'#333333'} />
              {expanded ? (
                <>
                  {dictionary?.hideOrderSummery} <FiChevronUp />
                </>
              ) : (
                <>
                  {dictionary?.showOrderSummery}
                  <FiChevronDown />
                </>
              )}
            </div>
            <div className="total h3">{basket.totalPriceIncludeShippingFormatted}</div>
          </button>
        )}
        {basket.priorityCountdownDate && (
          <Countdown
            label={dictionary?.timeBeforeReservationExpires}
            millisecondsLeft={basket.priorityCountdownDate}
            onCompleted={() => {
              console.log('Countdown completed');
            }}
          />
        )}
        <animated.div className="compact-wrapper" style={props}>
          <div ref={ref} className={`product-items ${expanded ? 'expanded' : ''}`}>
            {basket.items.map((item, index) => {
              const variants =
                item.selectedVariants && item.selectedVariants.length
                  ? `${item.selectedVariants.map((variant) => variant.attributeName).join(' / ')}`
                  : '';
              return (
                <React.Fragment key={item._id}>
                  {index > 0 && <hr />}
                  <div className={`basket-line ${item.errorMessage ? 'has-error' : ''}`}>
                    <div className="thumb">
                      {item.image && (
                        <img
                          src={buildImgixUrl(item.image.src, {
                            width: 100,
                          })}
                          alt={item.name}
                          className="basket-thumb"
                        />
                      )}
                      <div className="image-overlay" />
                    </div>

                    <div className="info">
                      {item.artist?.name && <div className="artist">{item.artist.name}</div>}
                      <div className="title h4">{item.name}</div>
                      {item.subText && <div className="subtext h4">{item.subText}</div>}
                      {variants && <div className="variants">{variants}</div>}
                      {(item.customTextVariant.active || item.customTextVariant2.active) && (
                        <>
                          <div className="custom-text">
                            {dictionary?.customTextVariantLabel}:{' '}
                            <span>
                              {item.customTextVariantValue || ''}
                              {item.customTextVariantValue && item.customTextVariant2Value && (
                                <span className="sep"> / </span>
                              )}
                              {item.customTextVariant2Value || ''}
                            </span>
                          </div>
                        </>
                      )}
                      {/* {item.finitePreorder && item.shippingStartDate && (
                        <div className="finite-green">  
                          {dictionary?.finiteBasketItem}{' '}
                          {item.shippingStartDate}
                        </div>
                      )} */}
                      <div className="price-amount">
                        <QuantityPicker
                          quantity={item.amount}
                          onDecrease={() => changeQuantity(item._id, item.amount - 1)}
                          onIncrease={() => changeQuantity(item._id, item.amount + 1)}
                        />
                        <Button
                          className="remove"
                          onClick={() => {
                            changeQuantity(item._id, 0);
                          }}
                          size="small"
                          icon={FiTrash2}
                          transparent
                        />
                        <div className="prices h3">
                          {item.beforePriceFormatted && (
                            <span className="before-price">{item.beforePriceFormatted}</span>
                          )}
                          {item.totalPriceFormatted}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!IS_PRODUCTION && (
                    <div>
                      {item.reservations?.map((reservation) => {
                        return (
                          <div className="reservation" key={reservation._id}>
                            {reservation.status}: {reservation.amount} ({reservation.error})
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {item.errorMessage && <div className="error-message">{item.errorMessage}</div>}
                </React.Fragment>
              );
            })}

            {!compact && shop!.hasVouchers && (
              <>
                <hr />
                <div className="extra-info">
                  {showVoucher ? (
                    <div className="form-item">
                      <Input
                        placeholder={dictionary?.voucher}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        onBlur={onVoucherBlur}
                      />
                    </div>
                  ) : (
                    <Button onClick={() => setShowVoucher(true)} size="small" transparent noPadding secondary>
                      {dictionary?.showVoucherInput}
                    </Button>
                  )}
                </div>
              </>
            )}

            <div className="totals">
              <div className="total-basket-line">
                <div className="info">{dictionary?.subtotal}</div>
                <div className="price">{basket.totalPriceFormatted}</div>
              </div>

              {basket.voucher && (
                <div className="total-basket-line discount">
                  <div className="info">
                    {dictionary?.discount} {basket.voucher.discount}%
                  </div>
                  <div className="price">{basket.voucher.discountPriceFormatted}</div>
                </div>
              )}

              {basket.shippingPrice > 0 ? (
                <div className="total-basket-line">
                  <div className="info">{dictionary?.shippingFee}</div>
                  <div className="price">{basket.shippingPriceFormatted}</div>
                </div>
              ) : null}

              <div className="total-basket-line">
                <div className="info">{dictionary?.vat}</div>
                <div className="price">{basket.vatFormatted}</div>
              </div>
              <div className="total-basket-line total">
                <div className="info h2">{dictionary?.total}</div>
                <div className="price h2">{basket.totalPriceIncludeShippingFormatted}</div>
              </div>
            </div>
            {basket.currency === 'DKK' && basket.totalPriceIncludeShipping > 0 && !hideBottom && (
              <div className="viabill-container">
                <Viabill view="basket" price={basket.totalPriceIncludeShipping} />
              </div>
            )}
          </div>
        </animated.div>
        {!hideBottom && (
          <div className="conditions">
            <Controller
              render={({ field: { value, name } }) => (
                <Checkbox
                  onChange={(e) => {
                    setValue(name, e.target.checked);
                  }}
                  name={name}
                  checked={value || false}
                  label={dictionary.acceptConditions!}
                  errorMessage={errors.conditions?.message}
                />
              )}
              rules={{ required: dictionary?.validationConditions }}
              name="conditions"
              control={control}
              defaultValue={customer?.conditions || false}
            />
          </div>
        )}

        {!hideBottom && (
          <div className="bottom">
            {showBackToShopButton && (
              <Button onClick={() => router.push('/')} icon={FiChevronLeft} transparent>
                {dictionary?.backToShop}
              </Button>
            )}
            <Button
              icon={FiChevronRight}
              loading={submitting}
              confirmText={basket.noticeMessage}
              onClick={handleSubmit(onSubmit)}
            >
              {dictionary?.goToCheckout}
            </Button>
          </div>
        )}
      </form>
    </StyledBasket>
  );
};

const StyledEmptyBasket = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;

  ${mqFrom.tablet} {
    font-size: 2rem;
  }
`;

const StyledBasket = styled.div`
  margin: 0 0 2rem 0;

  &.basket-wrapper {
    .countdown {
      display: flex;
      justify-content: center;
      padding: 1rem 1rem;
      margin-top: 0rem;
      background: var(--error-foreground);
      color: white;
      margin-bottom: 2rem;
    }
  }

  &.compact {
    margin-bottom: 0;
    ${mqFrom.desktop} {
      /* display: none; */
    }
  }

  h3 {
    margin: 1rem 0 3rem 0;
  }

  hr {
    margin: 2rem 0;
  }

  .header-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 var(--side-padding);
  }

  .summery {
    background-color: var(--ultra-light-background);
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    display: block;
    width: 100%;
    padding: 0.7rem var(--side-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);

    &.expanded {
      margin-bottom: 2rem;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .h3 {
      margin: 0.5rem 0;
    }

    svg {
      margin: 0 0.5rem;
    }

    /* ${mqFrom.desktop} {
      display: none;
    } */
  }

  .compact-wrapper {
    overflow-y: hidden;
    overflow-x: visible;
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);

    ${mqFrom.desktop} {
      overflow-y: visible;
      overflow-x: hidden;
    }
  }
  .product-items {
    .basket-line {
      position: relative;
      display: flex;
      align-items: flex-start;

      &.has-error {
        .thumb,
        .prices,
        .info > * {
          opacity: 0.5;
        }

        .price-amount {
          opacity: 1 !important;
        }
        border: 1px solid var(--error-foreground) !important;
        border-radius: 4px;
        padding: 1rem;
        margin-left: -1rem;
        margin-right: -1rem;
      }

      .thumb {
        margin-right: 1rem;
        position: relative;
      }
      .info {
        flex: 1 1 0;

        button.remove {
        }
        .artist {
          margin: 0 0 0.4rem 0;
          font-weight: 700;
          font-family: var(--font-family-display);
          font-size: 0.9rem;
        }
        .title {
          margin: 0 0 0.1rem 0;
        }
        .subtext {
          margin: 0.1rem 0;
          color: var(--base-dimmed-foreground);
        }
        .variants {
          color: var(--primary-background);
          font-size: 1rem;
        }

        .custom-text {
          font-size: 0.8rem;
          font-size: 0.9rem;
          font-weight: 600;
          margin: 0.1rem 0;
          span {
            color: var(--primary-background);
          }
        }

        .price-amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0.5rem 0;
          flex-wrap: wrap;

          > button {
            margin: 0 0.8rem;
          }

          .prices {
            margin: 0 0 0 1rem;
            flex: 1 1 0;
            text-align: right;
            font-weight: 700;

            .before-price {
              margin: 0 1rem;
              font-size: 0.9rem;
              text-decoration: line-through;
              color: var(--base-dimmed-foreground);
            }
          }
        }
      }
    }

    .error-message {
      color: var(--error-foreground);
      font-size: 1rem;
      text-align: right;
    }
  }

  .extra-info {
    margin: 0rem 0 2rem 0;
  }

  .totals {
    background-color: var(--ultra-light-background);
    margin-top: 2rem;
    margin-left: calc(var(--side-padding) * -1);
    margin-right: calc(var(--side-padding) * -1);
    padding: 1rem var(--side-padding);
    .total-basket-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.5rem 0;
      font-weight: 600;
    }
  }

  .viabill-container {
    display: block;
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: flex-end;
  }

  .conditions {
    margin: 1rem 0;
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
  }

  .bottom {
    margin: 2rem 0;
    padding-left: var(--side-padding);
    padding-right: var(--side-padding);
  }
`;

export default Basket;
