/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import Link from 'next/link';
import { mqFrom, mqUntil, shadows } from 'styles/styles';
import React, { useContext, useRef } from 'react';
import { ContextProps, GlobalContext } from 'context/global-context';
import Button from 'components/formitems/button';
import { FiX } from 'react-icons/fi';
import { useClickAway } from 'react-use';
import CurrencyPicker from 'components/core/currency-picker';
import LanguagePicker from 'components/core/language-picker';
import CascadeNavigation from './cascade-navigation';
import { SearchBar } from './searchbar';
import SocialIcons from './social-icons';
import { isExternalLink } from 'utils/helpers';

export type SidebarNavigationProps = {}; // eslint-disable-line

type StyledProps = {
  open: boolean;
};

export const SidebarNavigation: React.FC<SidebarNavigationProps> = () => {
  const { popupState, setPopupState, theme, shop } = useContext<ContextProps>(GlobalContext);
  const ref = useRef(null);

  useClickAway(ref, (e) => {
    if (popupState === 'sidebar') {
      if ((e.target as HTMLElement) && (e.target as Element).closest('#modal-portal')) {
        console.log('Dont close on click inside modal');
      } else {
        setPopupState('none');
      }
    }
  });

  return (
    <SidebarNavigationStyled className="sidebar-navigation" ref={ref} open={popupState === 'sidebar'}>
      <div className="top-bar">
        <CurrencyPicker />
        <LanguagePicker />
        <Button onClick={() => setPopupState('none')} icon={FiX} transparent />
      </div>

      {!theme?.settings?.disableSearch && (
        <div className="searchbar-wrapper">
          <SearchBar />
        </div>
      )}
      <CascadeNavigation />
      <nav className="support">
        <ul>
          {shop!.footerLinks.map((item) => {
            if (isExternalLink(item.url)) {
              return (
                <li key={item.url}>
                  <a href={item.url} target="_blank" rel="noreferrer noopener">
                    {item.label}
                  </a>
                </li>
              );
            }
            return (
              <li key={item.url}>
                <Link prefetch={false} href={item.url}>
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <SocialIcons />
    </SidebarNavigationStyled>
  );
};

const SidebarNavigationStyled = styled.aside<StyledProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(${(props) => (props.open ? 0 : '-100%')});
  width: 90%;
  max-width: 500px;
  height: 100vh;
  background: var(--sidebar-background);
  z-index: 101;
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  ${shadows.right};
  display: flex;
  flex-direction: column;

  ${mqFrom.desktop} {
    display: none;
  }

  .top-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;

    > div {
      margin-right: 1rem;
    }

    > button {
      margin-left: auto;
    }
  }

  .searchbar-wrapper {
    padding: 1rem;
    /* background: var(--border); */
    background: var(--sidebar-hover);
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    .react-autosuggest__suggestions-container {
      margin-top: 1rem;
    }
  }

  nav.cascade {
    margin: 1rem 0;
    flex: 1 1 0;
    overflow-y: auto;
  }

  nav.support {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      > li {
        display: block;

        > a {
          display: block;
          justify-content: stretch;
          align-items: center;
          width: 100%;
          color: var(--base-dimmed-foreground);
          padding: 0.3rem 1rem;
          flex: 1 1 0;
          text-decoration: none;
          font-size: 1.4rem;
          font-family: var(--font-family-display);
          font-weight: 400;
          position: relative;

          ${mqUntil.mobile} {
            font-size: 1rem;
          }

          &:hover,
          &.active {
            background: var(--sidebar-hover);
          }
        }
      }
    }
  }

  .socials {
    padding: 1rem;
  }
`;

export default SidebarNavigation;
