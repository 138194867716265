/* eslint-disable @typescript-eslint/no-empty-function */
import Head from 'next/head';
import Header from 'components/core/header';
import Footer from '../components/core/footer';
import CookieConsentBanner from 'components/core/cookie-consent-banner';
import MessageBar from 'components/core/message-bar';

type LayoutProps = {
  cssClassName: string;
  simple?: boolean;
  empty?: boolean;
  children: ChildrenProperty;
};
const Layout: React.FC<LayoutProps> = ({ children, cssClassName, simple = false, empty = false }) => {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,  maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
        />
      </Head>

      {!empty && <Header simple={simple} />}
      <main id="MainContent" className={cssClassName}>
        {!empty && <MessageBar />}
        {children}
      </main>
      {!simple && !empty && <Footer />}
      <CookieConsentBanner />
    </>
  );
};
export default Layout;
