import Button from 'components/formitems/button';
import { ContextProps, GlobalContext } from 'context/global-context';
import { useOrderStore } from 'data/orders';
import router, { useRouter } from 'next/router';
import { ConsentSettings } from 'r2d2';
import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { mqUntil } from 'styles/styles';
import Modal from './modal';

// export type CookieConsentBannerProps = {};

export const CookieConsentBanner: React.FC = () => {
  const router = useRouter();
  const [modalVisible, setModalVisible] = useState(false);
  const [touched, setTouched] = useState(false);
  const [cookieValues, setCookieValues] = useState<ConsentSettings>({
    analytics: null,
    advertising: null,
  });

  const orderFetched = useOrderStore((state) => state?.fetched);
  const cookieConsent = useOrderStore((state) => state?.order?.cookieConsent);
  const setCookieConsent = useOrderStore((state) => state.setCookieConsent);

  const { dictionary } = useContext<ContextProps>(GlobalContext); // eslint-disable-line

  // Find change cookie consent button på side

  const changeConsent = (event: Event): void => {
    event.preventDefault();
    setModalVisible(true);
  };

  useEffect(() => {
    if (cookieConsent) {
      setCookieValues({
        analytics: cookieConsent.analytics || false,
        advertising: cookieConsent.advertising || false,
      });
    }
  }, [cookieConsent]);

  useEffect(() => {
    const cookieConsentButton: HTMLElement = document.getElementById('changeCookieConsentButton') as HTMLElement;
    const currentCookieConsentState: HTMLElement = document.getElementById('currentCookieConsentState') as HTMLElement;
    const currentCookieConsentId: HTMLElement = document.getElementById('currentCookieConsentId') as HTMLElement;
    const currentCookieConsentDate: HTMLElement = document.getElementById('currentCookieConsentDate') as HTMLElement;

    if (currentCookieConsentState) {
      let currentState = dictionary?.cookieConsentStateRejected;
      if (cookieConsent?.advertising) {
        currentState = dictionary?.cookieConsentStateAdvertising;
      }
      if (cookieConsent?.analytics) {
        currentState = dictionary?.cookieConsentStateAnalytics;
      }
      if (cookieConsent?.advertising && cookieConsent?.analytics) {
        currentState = dictionary?.cookieConsentStateFull;
      }
      currentCookieConsentState.innerText = currentState;
    }

    if (currentCookieConsentId) {
      currentCookieConsentId.innerText = cookieConsent?.id || 'N/A';
    }
    console.log('cookieConsent', cookieConsent);

    if (currentCookieConsentDate) {
      currentCookieConsentDate.innerText = cookieConsent?.date
        ? new Intl.DateTimeFormat(router.locale, {
            dateStyle: 'full',
            timeStyle: 'long',
          }).format(new Date(cookieConsent?.date))
        : 'N/A';
    }
    if (cookieConsentButton) {
      cookieConsentButton.addEventListener('click', changeConsent); // @ts-ignore
      return () => {
        cookieConsentButton.removeEventListener('click', changeConsent);
      };
    }
  }, [router.asPath, cookieConsent, router.locale]); // eslint-disable-line

  const onAcceptAll = (): void => {
    setCookieConsent(
      {
        settings: {
          analytics: true,
          advertising: true,
        },
      },
      { locale: router.locale!, path: router.asPath },
    );
  };
  const onRejectAll = (): void => {
    setCookieConsent(
      {
        settings: {
          analytics: false,
          advertising: false,
        },
      },
      { locale: router.locale!, path: router.asPath },
    );
  };

  const onResetAll = (): void => {
    setCookieValues({
      analytics: null,
      advertising: null,
    });
  };

  const onManageCookies = (): void => {
    setModalVisible(true);
  };

  const onSaveChanges = (): void => {
    setModalVisible(false);
    setCookieConsent({ settings: cookieValues }, { locale: router.locale!, path: router.asPath });
  };

  const setCookieValue = (key: string, value: boolean): void => {
    setCookieValues({
      ...cookieValues,
      [key]: value,
    });
    setTouched(true);
  };

  const renderRadioButtons = (key: 'analytics' | 'advertising'): React.ReactElement => (
    <div className="radio-buttons">
      <button
        type="button"
        className={`radio ${cookieValues[key] === false ? 'selected' : ''}`}
        onClick={(): void => setCookieValue(key, false)}
      >
        <span>{dictionary?.cookieConsentReject}</span>
      </button>
      <button
        type="button"
        className={`radio ${cookieValues[key] === true ? 'selected' : ''}`}
        onClick={(): void => setCookieValue(key, true)}
      >
        <span>{dictionary?.cookieConsentAccept}</span>
      </button>
    </div>
  );

  return (
    <StyledCookieConsentBanner>
      {orderFetched && !cookieConsent && (
        <div className="cookie-consent">
          <div
            className="message"
            dangerouslySetInnerHTML={{
              __html: dictionary?.cookieConsentMessage,
            }}
          ></div>
          <div className="buttons">
            <Button size="small" onClick={onRejectAll}>
              <span>{dictionary?.cookieConsentRejectAll}</span>
            </Button>
            <Button size="small" onClick={onAcceptAll}>
              <span>{dictionary?.cookieConsentAcceptAll}</span>
            </Button>

            <Button size="small" onClick={onManageCookies}>
              <span>{dictionary?.cookieConsentManageCookies}</span>
            </Button>
          </div>
        </div>
      )}
      <Modal visible={modalVisible} close={() => setModalVisible(false)}>
        <StyledCookieConsentBannerInner>
          <div className="manage-cookies-modal">
            <h1>{dictionary?.cookieConsentPopupHeader}</h1>
            <div className="scrollable-content">
              <p
                dangerouslySetInnerHTML={{
                  __html: dictionary?.cookieConsentPopupBody,
                }}
              />

              <h2>{dictionary?.cookieConsentRequiredHeader}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: dictionary?.cookieConsentRequiredBody,
                }}
              />
              <h2>{dictionary?.cookieConsentAnalyticsHeader}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: dictionary?.cookieConsentAnalyticsBody,
                }}
              />
              {renderRadioButtons('analytics')}

              <h2>{dictionary?.cookieConsentAdvertisingHeader}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: dictionary?.cookieConsentAdvertisingBody,
                }}
              />
              {renderRadioButtons('advertising')}
            </div>
            <div className="buttons">
              <Button disabled={!touched} size="small" onClick={onResetAll}>
                <span>{dictionary?.cookieConsentResetAll}</span>
              </Button>
              <Button disabled={!touched} size="small" onClick={onSaveChanges}>
                <span>{dictionary?.cookieConsentSaveChanges}</span>
              </Button>
            </div>
          </div>
        </StyledCookieConsentBannerInner>
      </Modal>
    </StyledCookieConsentBanner>
  );
};

export const StyledCookieConsentBanner = styled.div`
  .cookie-consent {
    background: var(--cookie-consent-background);
    color: var(--cookie-consent-foreground);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem var(--side-padding);
    /* border-top: 2px solid var(--primary-background); */

    .message {
      flex: 1 1 0;
      padding-right: 1rem;

      a {
        color: var(--cookie-consent-foreground);
        text-decoration: underline;
      }
    }

    .buttons {
      display: flex;
      gap: 0.8rem;
      align-items: flex-start;

      button {
        margin-left: 0rem;
      }
    }

    ${mqUntil.wide} {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
    ${mqUntil.tablet} {
      .buttons {
        flex-direction: column;
        gap: 0.4rem;

        botton {
          flex: 1 1 0;
          width: 100%;
        }
      }
    }
  }
`;

const StyledCookieConsentBannerInner = styled.div`
  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  p {
  }

  .manage-cookies-modal {
    display: flex;
    flex-direction: column;
  }
  .scrollable-content {
    overflow: auto;
    height: calc(95vh - 260px);
    max-height: 600px;
    margin-bottom: 1rem;
  }

  .radio-buttons {
    display: flex;

    button.radio {
      margin-bottom: 13px;
      margin-right: 34px;
      display: flex;
      align-items: center;
      cursor: pointer;
      border: none;
      position: relative;
      padding: 3px;

      span {
        display: inline-block;
        font-size: 15px;
        line-height: 20px;
        padding-left: 8px;
      }

      &:before {
        display: block;
        height: 24px;
        width: 24px;
        content: '';
        border-radius: 50%;
        border: 2px solid #000000;
        background-color: #ffffff;
      }

      &.selected {
        &:before {
          border: 2px solid var(--secondary-background);
        }

        &:after {
          display: block;
          position: absolute;
          top: 7px;
          left: 7px;
          height: 16px;
          width: 16px;
          content: '';
          border-radius: 50%;
          background-color: var(--secondary-background);
        }
      }
    }
  }

  .close-btn {
    float: right;
    z-index: 1;
    margin: 2px;
    padding: 12px;
    border: none;
    cursor: pointer;
    font-family:
      Segoe UI,
      SegoeUI,
      Arial,
      sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #666;
    background-color: #fff;
  }

  .buttons {
    display: flex;
    padding: 0;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;

    button {
      margin: 0;
    }

    ${mqUntil.tablet} {
      padding: 1rem 0 0 0;
    }
  }
`;

export default CookieConsentBanner;
