// @ts-nocheck

import { captureException } from './sentry';
import Cookie from 'js-cookie';

/**
 * React Facebook Pixel Module
 *
 * @package react-facebook-pixel
 * @author  Zain Sajjad <zsajjad93@gmail.com>
 */

//
let debug = false;

/**
 * Utilities
 */

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args): void => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args): void => {
  if (!debug) {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...['[react-facebook-pixel]'].concat(args));
};

const verifyInit = (): boolean => {
  if (!window?.fbq) {
    warn('Pixel not initialized before using call ReactPixel.init with required params');
  }
  return !!(process.browser && window?.fbq);
};

//
const defaultOptions = {
  autoConfig: true,
  debug: false,
};

//

export const initPixel = (
  pixelId: string,
  locale: string,
  customer: CustomerInfoType,
  options = defaultOptions,
): void => {
  if (process.browser) {
    try {
      const advancedMatching = {
        em: customer?.email?.toLowerCase() || '',
        fn: customer?.firstName?.toLowerCase() || '',
        ln: customer?.lastName?.toLowerCase() || '',
        ph: customer?.firstName?.toLowerCase() || '',
        external_id: customer?.firstName?.toLowerCase() || '',
        ct: customer?.city?.toLowerCase().split(' ').join('') || '',
        zp: customer?.zipcode || '',
        country: customer?.countryCode?.toLowerCase() || '',
      };
      /* eslint-disable */
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      /* eslint-enable */

      if (!pixelId) {
        warn('Please insert pixel id for initializing');
      } else {
        if (options.autoConfig === false) {
          fbpixel('set', 'autoConfig', false, pixelId); // eslint-disable-line no-undef
        }

        fbpixel('init', pixelId, advancedMatching); // eslint-disable-line no-undef

        debug = options.debug;
      }
    } catch (err) {
      captureException(err, { from: 'fbpixel()', args });
    }
  }
};

export const fbPageView = (): void => {
  if (process.browser) {
    if (!verifyInit()) {
      return;
    }

    fbpixel('track', 'PageView'); // eslint-disable-line no-undef

    if (debug) {
      log("called fbpixel('track', 'PageView');");
    }
  }
};
export const fbTrack = (title: string, data: Record<string, unknown>, callback?: () => void): void => {
  if (process.browser) {
    if (verifyInit()) {
      fbpixel('track', title, data); // eslint-disable-line no-undef

      if (debug) {
        log(`called fbpixel('track', '${title}');`);

        if (data) {
          log('with data', data);
        }
      }
    }
    if (callback) {
      callback();
    }
  }
};

export const fbTrackCustom = (event: string, data: Record<string, unknown>): void => {
  if (process.browser) {
    if (!verifyInit()) {
      return;
    }

    fbpixel('trackCustom', event, data); // eslint-disable-line no-undef

    if (debug) {
      log(`called fbpixel('trackCustom', '${event}');`);

      if (data) {
        log('with data', data);
      }
    }
  }
};

export const fbGrantConsent = (): void => {
  if (process.browser) {
    if (!verifyInit()) {
      return;
    }

    fbpixel('consent', 'grant');

    if (debug) {
      log(`called fbpixel('consent', 'grant');`);
    }
  }
};

export const fbRevokeConsent = (): void => {
  if (process.browser) {
    if (!verifyInit()) {
      return;
    }

    fbpixel('consent', 'revoke');

    if (window._fbp) {
      delete window._fbp;
    }

    const domainArr = document.domain.split('.');
    const rootDomain = `.${domainArr[domainArr.length - 2]}.${domainArr[domainArr.length - 1]}`;
    // Root domainfordi at teknisk set skal man fjerne cookie på .domain.dk

    // Remove the default cookies
    Cookie.remove('_fbp', { path: '/', domain: document.domain });
    Cookie.remove('_fbp', { path: '' });
    Cookie.remove('_fbp', { path: '', domain: rootDomain });

    if (debug) {
      log(`called fbpixel('consent', 'revoke');`);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fbpixel = (...args): void => {
  // eslint-disable-line
  if (process.browser) {
    try {
      if (!verifyInit()) {
        return;
      }

      window.fbq(...args); // eslint-disable-line no-undef

      if (debug) {
        log(`called fbq('${args.slice(0, 2).join("', '")}')`);

        if (args[2]) {
          log('with data', args[2]);
        }
      }
    } catch (err) {
      captureException(err, { from: 'fbpixel()', args });
    }
  }
};
