// import { useContext } from 'react';
import { ContextProps, GlobalContext } from 'context/global-context';
import Link from 'next/link';
import { useContext } from 'react';
import styled from 'styled-components';
import { isExternalLink } from 'utils/helpers';
import SocialIcons from './social-icons';

const Footer: React.FC = () => {
  const { shop } = useContext<ContextProps>(GlobalContext);
  if (!shop) return null;

  return (
    <StyledFooter>
      <div className="links">
        <nav className="support">
          <ul>
            {shop!.footerLinks.map((item) => {
              if (isExternalLink(item.url)) {
                return (
                  <li key={item.url}>
                    <a href={item.url} target="_blank" rel="noreferrer noopener">
                      {item.label}
                    </a>
                  </li>
                );
              }
              return (
                <li key={item.url}>
                  <Link prefetch={false} href={item.url}>
                    {item.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <SocialIcons />
    </StyledFooter>
  );
};
const StyledFooter = styled.footer`
  background: var(--footer-background);
  color: var(--footer-foreground);
  padding: 3rem var(--side-padding);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;

  .socials {
    button {
      background-color: var(--footer-foreground);

      svg[stroke]:not([stroke='none']),
      svg:not([stroke]) {
        stroke: var(--footer-background);
      }

      svg[fill]:not([fill='none']),
      svg:not([fill]) {
        fill: var(--footer-background);
      }
    }
  }

  .support {
    margin: 0 0 2rem 0;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      li {
        margin: 0.5rem 1rem;

        a {
          color: var(--footer-foreground);
        }
      }
    }
  }
`;

export default Footer;
