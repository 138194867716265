import { FC, useContext } from 'react';
import styled from 'styled-components';
import { ContextProps, GlobalContext } from 'context/global-context';
import { mqFrom } from 'styles/styles';
import BurgerIcon from 'icons/burger';

const Burger: FC = () => {
  const { setPopupState } = useContext<ContextProps>(GlobalContext);
  return (
    <StyledBurger
      type="button"
      className="burger"
      onClick={() => {
        setPopupState((val) => (val === 'sidebar' ? 'none' : 'sidebar'));
      }}
    >
      <BurgerIcon size={24} />
    </StyledBurger>
  );
};
const StyledBurger = styled.button`
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  padding: 0 1rem;

  ${mqFrom.tablet} {
    padding: 0 2rem;
  }

  ${mqFrom.desktop} {
    display: none;
  }
`;

export default Burger;
