import styled, { css } from 'styled-components';

export type LoaderProps = {
  negative?: boolean;
  fixed?: boolean;
  title?: string;
  size?: number;
  className?: string;
};

type StyledProps = {
  negative: boolean;
  fixed: boolean;
  size: number;
};

export const Loader: React.FC<LoaderProps> = ({
  fixed = false,
  negative = false,
  title = '',
  size = 30,
  className = '',
}) => (
  <StyledLoader className={`load-wrapper ${className}`} negative={negative} fixed={fixed} size={size}>
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50" fill="none" stroke={negative ? 'white' : 'black'}>
        <circle className="path" cx="50" cy="50" r="20" strokeWidth="4" strokeMiterlimit="10" />
      </svg>
      {title && <div className="label">{title}</div>}
    </div>
  </StyledLoader>
);
const StyledLoader = styled.div<StyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ fixed, size }) =>
    fixed
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin-top: ${(size * -1) / 2}px;
          margin-left: ${(size * -1) / 2}px;
        `
      : css`
          position: relative;
        `}

  .loader {
    position: relative;
    width: ${(props) => props.size}px;

    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    .label {
      text-align: center;
      padding-top: 50px;
      font-size: 13px;
      font-weight: 500;
      width: 200px;
      margin-left: -85px;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: var(--secondary-background);
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
`;

export default Loader;
