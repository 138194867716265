import { FC } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import styled from 'styled-components';
import Button from './button';

export type QuantityPickerProps = {
  quantity: number;
  onDecrease: () => void;
  onIncrease: () => void;
};

export const QuantityPicker: FC<QuantityPickerProps> = ({ onDecrease, onIncrease, quantity }) => {
  return (
    <StyledQuantityPicker>
      <Button icon={FiMinus} secondary onClick={onDecrease} size="small" />
      <div>{quantity}</div>
      <Button icon={FiPlus} secondary onClick={onIncrease} size="small" />
    </StyledQuantityPicker>
  );
};

export const StyledQuantityPicker = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem 0;
  > button {
    background: var(--grey);
    svg {
      transform: scale(1.2) !important;
    }
  }

  > div {
    margin: 0 0.7rem;
    /* font-size: 0.8rem; */
    font-weight: 700;
  }
`;

export default QuantityPicker;
