import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useScroll } from 'utils/hooks';
import { ContextProps, GlobalContext } from 'context/global-context';
import { mqFrom, mqUntil } from 'styles/styles';
import Link from 'next/link';
import { buildImgixUrl } from 'utils/helpers';
import Button from 'components/formitems/button';
import { FiSearch, FiX } from 'react-icons/fi';
import Navigation from './navigation';
import Burger from './burger';
import CartButton from './cart-button';
import CurrencyPicker from './currency-picker';
import LanguagePicker from './language-picker';
import { SearchBar } from './searchbar';
import { ImageFile } from 'r2d2';

export type HeaderProps = {
  simple?: boolean;
};

export type StyledHeaderProps = {
  logo: ImageFile | null;
};

export const Header: FC<HeaderProps> = ({ simple = false }) => {
  const { isOnTop } = useScroll(30);
  const { shop, theme, pages = [] } = useContext<ContextProps>(GlobalContext);
  const [showSearch, setShowSearch] = useState(false);

  if (!shop) {
    return null;
  }

  const { logo, name } = shop!;

  return (
    <StyledHeader className={classNames({ collapsed: !isOnTop, simple })} logo={logo}>
      {!simple && <Burger />}
      {!simple && pages.filter((item) => !item.hideInNavigation).length === 0 ? (
        <div className="center-spacer"></div>
      ) : (
        <div className="center-spacer-with-navitems"></div>
      )}
      {logo?.src ? (
        <div className="logo">
          <Link prefetch={false} href="/">
            <div className="logo-img" data-width={logo?.width} data-height={logo?.height}></div>
          </Link>
        </div>
      ) : (
        <div className="logo text">
          <Link prefetch={false} href="/">
            <span>{name}</span>
          </Link>
        </div>
      )}
      {!simple && (
        <>
          <Navigation collapsed={!isOnTop} />

          <div className="currency-wrapper">
            <CurrencyPicker />
          </div>
          <div className="language-wrapper">
            <LanguagePicker />
          </div>
          {!theme?.settings?.disableSearch && (
            <div className={`search-wrapper ${showSearch ? 'show' : 'hide'}`}>
              <Button
                icon={FiSearch}
                size="small"
                transparent
                className="show-btn"
                aria-label="Søg"
                onClick={() => {
                  setShowSearch(true);
                  const input: HTMLInputElement | null = document.querySelector(
                    'header .search-wrapper input.react-autosuggest__input',
                  );
                  if (input) {
                    input.focus();
                  }
                }}
              />
              <Button
                icon={FiX}
                size="small"
                className="hide-btn"
                transparent
                aria-label="Fjern søg"
                onClick={() => setShowSearch(false)}
              />
              <SearchBar />
            </div>
          )}
          <CartButton />
        </>
      )}
    </StyledHeader>
  );
};

export const StyledHeader = styled.header<StyledHeaderProps>`
  background-color: var(--header-background);
  height: var(--navigation-height);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: var(--header-foreground);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  z-index: 100;
  transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 0 var(--side-padding);

  &.simple {
    justify-content: center;
  }

  .center-spacer {
    flex: 1 1 0;
    margin-left: 200px;
  }

  .center-spacer-with-navitems {
    display: none;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-img {
      background-image: ${({ logo }) => (logo?.src ? `url(${buildImgixUrl(logo?.src)})` : 'none')};
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: calc(var(--navigation-height) - 2.2rem);
      width: ${({ logo }) => `calc((var(--navigation-height) - 1rem) * ${(logo?.width ?? 0) / (logo?.height ?? 0)})`};
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
      max-width: calc(100vw - 160px);

      ${mqFrom.desktop} {
        max-width: ${({ logo }) => logo?.width ?? 0}px;
      }
    }
    img {
      /* width: 100%; */
      height: 90%;
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform-origin: 50% 50%;
    }

    &.text {
      font-size: 2rem;
      font-weight: 800;
    }
  }

  &.collapsed {
    height: var(--navigation-height-collapsed);
    box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028),
      0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042),
      0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);

    .logo {
      .logo-img {
        height: calc(var(--navigation-height-collapsed) - 1.4rem);
      }
      img {
        margin-left: 15%;
        /* width: 70%; */
      }
    }
  }

  nav {
    flex: 1;
  }

  .search-wrapper {
    align-self: center;
    margin-left: 1rem;

    .searchbar {
      position: absolute;
      top: 100%;
      right: 0;
      width: 0;
      transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    button {
      --base-foreground: var(--header-foreground);
      margin-left: 0;
    }

    .hide-btn {
      display: block;
    }
    .hide-btn {
      display: none;
    }

    &.show {
      .show-btn {
        display: none;
      }
      .hide-btn {
        display: block;
      }

      .searchbar {
        width: 500px;
      }
    }
  }
  .currency-wrapper {
    align-self: center;
    margin-left: 0.5rem;
  }
  .language-wrapper {
    align-self: center;
    margin-left: 0.5rem;
  }

  ${mqUntil.desktop} {
    nav,
    .center-spacer,
    .language-wrapper,
    .currency-wrapper,
    .search-wrapper {
      display: none;
    }
  }

  .cart-button,
  .burger {
    svg {
      path {
        fill: var(--header-foreground);
      }
    }
  }
`;

export default Header;
